import tools from '@/utils/tools';

export async function saveSetting(params,that){
	var url = "/backend/setting/save"
	return await tools.post(url,params,that)
}

export async function getSetting(params,that){
	var url = "/backend/setting/get"
	return await tools.post(url,params,that)
}

export async function getStatistics(params,that){
	var url = "/backend/setting/getStatistics"
	return await tools.post(url,params,that)
}
