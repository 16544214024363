import tools from '@/utils/tools';

export async function getPhotosList(params,that){
	var url = "/backend/photo/getPhotosList"
	return await tools.post(url,params,that)
}

export async function getPhotos(params,that){
	var url = "/backend/photo/getPhotos"
	return await tools.post(url,params,that)
}

export async function addPhotos(params,that){
	var url = "/backend/photo/addPhotos"
	return await tools.post(url,params,that)
}

export async function editPhotos(params,that){
	var url = "/backend/photo/editPhotos"
	return await tools.post(url,params,that)
}

export async function delPhotos(params,that){
	var url = "/backend/photo/delPhotos"
	return await tools.post(url,params,that)
}