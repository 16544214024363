import Vue from 'vue'
import Router from 'vue-router'
import index from '@/views/index'
import home from '@/views/home'
import login from '@/views/login'
import statistics from '@/views/statistics'

//设置
import set from '@/views/setting/set'
import administratorList from '@/views/setting/administratorList'
import changepassword from '@/views/setting/changepassword'

//管理员
import addUser from '@/views/user/addUser'
import editUser from '@/views/user/editUser'

//公司管理
import company from '@/views/company/company'
import staff from '@/views/company/staff'
import addStaff from '@/views/company/addStaff'
import editStaff from '@/views/company/editStaff'

//招聘管理
import schoolPosts from '@/views/recruit/schoolPosts'
import societyposts from '@/views/recruit/societyposts'
import insidePosts from '@/views/recruit/insidePosts'
import shuoboPosts from '@/views/recruit/shuoboPosts'
import addPosts from '@/views/recruit/addPosts'
import editPosts from '@/views/recruit/editPosts'
import jobCate from '@/views/recruit/jobCate'
import department from '@/views/recruit/department'
import apply from '@/views/recruit/apply'
import editApply from '@/views/recruit/editApply'
import pointlist from '@/views/recruit/pointlist'

//用户管理
import member from '@/views/member/member'
import talent from '@/views/member/talent'
import editMember from '@/views/member/editMember'
import edittalent from '@/views/member/edittalent'

//组图管理
import photos from '@/views/photos/index'
import addPhotos from '@/views/photos/addPhotos'
import editPhotos from '@/views/photos/editPhotos'

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      component: login
    },
    {
      path: '/login',
      component: login
    },
    {
      path: '/home',
      component: home,
      children: [
        {path: 'index', component: index},
				{name: 'home',path: 'statistics', component: statistics},
				
				//设置
				{path: 'set', component: set},
				{path: 'administratorList', component: administratorList},
				{path: 'changepassword', component: changepassword},
				
				//管理员
				{path: 'addUser', component: addUser},
				{path: 'editUser', component: editUser},
				
				//公司管理
				{path: 'company', component: company},
				{path: 'staff', component: staff},
				{path: 'addStaff', component: addStaff},
				{path: 'editStaff', component: editStaff},
				
				//招聘管理
				{path: 'schoolPosts', component: schoolPosts},
				{path: 'societyposts', component: societyposts},
				{path: 'insidePosts', component: insidePosts},
				{path: 'shuoboPosts', component: shuoboPosts},
				{path: 'addPosts', component: addPosts},
				{path: 'editPosts', component: editPosts},
				{path: 'jobCate', component: jobCate},
				{path: 'department', component: department},
				{path: 'apply', component: apply},
				{path: 'editApply', component: editApply},
				{path: 'pointlist', component: pointlist},
				
				//用户管理
				{path: 'talent', component: talent},
				{path: 'member', component: member},
				{path: 'editMember', component: editMember},
				{path: 'edittalent', component: edittalent},
				
				//组图管理
				{path: 'photos', component: photos},
				{path: 'addPhotos', component: addPhotos},
				{path: 'editPhotos', component: editPhotos},
      ]
    },
  ],
  linkActiveClass: 'current'
})