<template>
	<el-card class="box-card" shadow="hover">
		<div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item style="line-height: 28px;">事业部管理</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
			<el-button size="mini" type="primary" style="float:right;" @click="showBox">新增事业部</el-button>
		</div>
		<div class="text item">
			<el-tag v-for="tag in tags" @close="delDepartment(tag.id)" :key="tag.title" closable style="margin-right: 10px;margin-bottom: 10px;">{{tag.title}}</el-tag>
		</div>
		<el-dialog title="添加事业部" :visible.sync="showType">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="名称">
					<el-input v-model="form.title"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="addDepartment">保存</el-button>
					<el-button @click="showType=false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-card>
</template>

<script>
	import {delDepartment,getDepartmentList,addDepartment} from "@/api/department"
	export default {
		data() {
			return {
				tags:[],
				showType:false,
				form:{
					title:'',
					token:sessionStorage.getItem('token'),
				},
			}
		},
		methods: {
			showBox(data){
				this.showType = true
				this.form.title = ''
			},
			getList(){
			  getDepartmentList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.tags = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			addDepartment(){
				addDepartment(this.form,this).then(res=>{
					this.showType = false
					this.getList()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			delDepartment(id){
				delDepartment({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
					this.$message.error(err)
				})
			}
		},
		mounted () {
			this.getList()
		}
	}
</script>

<style>
</style>