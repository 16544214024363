<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.dividerLine {
    border-left: 5px solid #409eff;
    padding-left: 10px;
    line-height: 20px;
    font-weight: 700;
    font-size: 14px;
}
</style>
