<template>
	<div>
		<el-card class="box-card" shadow="hover">
			<div slot="header" class="clearfix" style="position:relative">
				<span style="display: inline-block;">
					<el-breadcrumb>
						<el-breadcrumb-item style="line-height: 28px;">数据统计</el-breadcrumb-item>
					</el-breadcrumb>
				</span>
			</div>
			<div class="text item">
				<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
					<el-form ref="form" inline label-width="140px">
						<el-form-item label="录入时间">
							<el-date-picker
								v-model="sreachForm.createTime"
								type="date"
								placeholder="选择日期">
							</el-date-picker>
							<span style="margin:0 20px;">至</span>
							<el-date-picker
								v-model="sreachForm.endTime"
								type="date"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" plain @click="setWeek">最近一周</el-button>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" plain @click="setMonth">最近一个月</el-button>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" plain @click="setThreeMonth">最近三个月</el-button>
						</el-form-item>
					</el-form>
					<el-form-item label="岗位类别">
						<el-select v-model="sreachForm.jobCate" placeholder="请选择岗位类别">
							<el-option label="全部" value="">全部</el-option>
							<el-option
								v-for="item in jobCateList"
								:key="item.id"
								:label="item.title"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="事业部">
						<el-select v-model="sreachForm.department" placeholder="请选择事业部">
							<el-option label="全部" value="">全部</el-option>
							<el-option
								v-for="item in departmentList"
								:key="item.id"
								:label="item.title"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
					<el-form-item label="招聘管理">
						<el-select v-model="sreachForm.cateid" placeholder="招聘管理">
							<el-option label="全部" value="0">全部</el-option>
							<el-option label="校园招聘" value="1">校园招聘</el-option>
							<el-option label="社会招聘" value="2">社会招聘</el-option>
							<el-option label="内部招聘" value="3">内部招聘</el-option>
							<el-option label="硕博招聘" value="4">硕博招聘</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="">
<!-- 						<el-select v-model="sreachForm.companyId" placeholder="请选择公司">
							<el-option label="全部" value="">全部</el-option>
							<el-option
								v-for="item in company"
								:key="item.value"
								:label="item.label"
								:value="item.value">
							</el-option>
						</el-select> -->
						<el-cascader
						    v-model="choosedcompanyId"
						    :options="company"
								@change="handleChangeCompany" clearable></el-cascader>
					</el-form-item>
					<el-form-item label="">
						<el-input v-model="sreachForm.words" placeholder="岗位|事业部|工作地点">
							<template slot="prepend"><i class="el-icon-search"></i></template>
						</el-input>
					</el-form-item>
					<el-form-item label="">
						<el-button type="primary" @click="getStatistics">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-card class="box-card" shadow="hover" style="margin-top: 10px;">
			<div class="grid-content">
				注册量
				<p class="num">{{regNum}}</p>
			</div>
			<div class="grid-content">
				来访量
				<p class="num">{{visitNum}}</p>
			</div>
			<div class="grid-content">
				投递量
				<p class="num">{{deliveryNum}}</p>
			</div>
			<div class="grid-content">
				通知面试量
				<p class="num">{{interviewNum}}</p>
			</div>
			<div class="grid-content">
				录用量
				<p class="num">{{employmentNum}}</p>
			</div>
		</el-card>
		<el-row :gutter="20">
			<el-col :span="18">
				<el-card class="box-card" shadow="hover" style="margin-top: 10px;">
					<div id="main" style="width: 100%;height:400px;"></div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover" style="margin-top: 10px;height:440px;">
					<div slot="header" class="clearfix">
						<span>游览量</span>
					</div>
					<div>
						<p v-for="(val,key) in postlist" :key="key" style="font-size: 14px;margin-top: 0;">
							<span>{{val.title}}</span>
							<span style="float: right;font-weight: bold;">{{val.num}}</span>
						</p>
					</div>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="18">
				<el-card class="box-card" shadow="hover" style="margin-top: 10px;">
					<div id="main1" style="width: 100%;height:400px;"></div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="box-card" shadow="hover" style="margin-top: 10px;height:440px;">
					<div slot="header" class="clearfix">
						<span>一键分享情况</span>
					</div>
					<div>
						<p v-for="(val,key) in memberlist" :key="key" style="font-size: 14px;margin-top: 0;">
							<span>{{val.realname ? val.realname : val.phone}}</span>
							<span style="float: right;font-weight: bold;">{{val.num}}</span>
						</p>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {getPostsList,delPosts} from "@/api/recruit"
	import {getJobCateList} from "@/api/jobCate"
	import {getDepartmentList} from "@/api/department"
	import {getCompanyList} from "@/api/company"
	import {getStatistics} from "@/api/setting"
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
				title:'',
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				company:[],
				jobCateList:[],
				departmentList:[],
				deliveryNum:0,
				employmentNum:0,
				interviewNum:0,
				regNum:0,
				visitNum:0,
				deliveryNumList:[],
				employmentNumList:[],
				interviewNumList:[],
				regNumList:[],
				visitNumList:[],
				postlist:[],
				memberlist:[],
				day:[],
				sharevisitNumList:[],
				choosedcompanyId:[],
				sreachForm:{
					createTime:'',
					endTime:'',
					jobCate:'',
					department:'',
					cateid:'0',
					companyId:'',
					words:'',
					status:'0',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			handleChangeCompany(val){
				this.sreachForm.companyId = val[val.length-1]
			},
			setWeek(){
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				this.sreachForm.createTime = start
				this.sreachForm.endTime = end
			},
			setMonth(){
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				this.sreachForm.createTime = start
				this.sreachForm.endTime = end
			},
			setThreeMonth(){
				const end = new Date();
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				this.sreachForm.createTime = start
				this.sreachForm.endTime = end
			},
			createExpenditureEcharts(){
				var main = document.getElementById('main');
				var myChart = echarts.init(main);
				var that = this
				var option = {
					title: {
					    text: '数据统计'
					  },
					  tooltip: {
					    trigger: 'axis'
					  },
					  legend: {
					    data: [ '注册量', '来访量', '投递量', '通知面试量', '录用量']
					  },
					  grid: {
					    left: '3%',
					    right: '3%',
					    bottom: '3%',
					    containLabel: true
					  },
					  toolbox: {
					    
					  },
					  xAxis: {
					    type: 'category',
					    boundaryGap: false,
					    data: that.day
					  },
					  yAxis: {
					    type: 'value'
					  },
					  series: [
					    {
					      name: '注册量',
					      type: 'line',
					      data: that.regNumList
					    },
					    {
					      name: '来访量',
					      type: 'line',
					      data: that.visitNumList
					    },
					    {
					      name: '投递量',
					      type: 'line',
					      data: that.deliveryNumList
					    },
					    {
					      name: '通知面试量',
					      type: 'line',
					      data: that.interviewNumList
					    },
					    {
					      name: '录用量',
					      type: 'line',
					      data: that.employmentNumList
					    }
					  ]
				};
				myChart.setOption(option);
			},
			createEcharts(){
				var main1 = document.getElementById('main1');
				var myChart1 = echarts.init(main1);
				var that = this
				var option = {
					title: {
					    text: '一键分享统计'
					  },
					  tooltip: {
					    trigger: 'axis'
					  },
					  legend: {
					    data: ['来访量']
					  },
					  grid: {
					    left: '3%',
					    right: '3%',
					    bottom: '3%',
					    containLabel: true
					  },
					  toolbox: {
					    
					  },
					  xAxis: {
					    type: 'category',
					    boundaryGap: false,
					    data: that.day
					  },
					  yAxis: {
					    type: 'value'
					  },
					  series: [
					    {
					      name: '来访量',
					      type: 'line',
					      data: that.sharevisitNumList
					    },
					  ]
				};
				myChart1.setOption(option);
			},
			getCompanyList(){
				getCompanyList({token:sessionStorage.getItem('token'),isCompany:1},this).then(res=>{
					this.company = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			getJobCateList(){
			  getJobCateList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.jobCateList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getDepartmentList(){
			  getDepartmentList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.departmentList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getStatistics(){
				getStatistics(this.sreachForm,this).then(res=>{
					this.deliveryNum = res.deliveryNum
					this.employmentNum = res.employmentNum
					this.interviewNum = res.interviewNum
					this.regNum = res.regNum
					this.visitNum = res.visitNum
					this.day = res.day
					this.deliveryNumList = res.deliveryNumList
					this.employmentNumList = res.employmentNumList
					this.interviewNumList = res.interviewNumList
					this.regNumList = res.regNumList
					this.visitNumList = res.visitNumList
					this.memberlist = res.memberlist
					this.postlist = res.postlist
					this.sharevisitNumList = res.sharevisitNumList
				  this.createExpenditureEcharts()
					this.createEcharts()
				}).catch(err=>{
				  this.$message.error(err);
				})
			},
		},
		mounted () {
			this.getCompanyList()
			this.getJobCateList()
			this.getDepartmentList()
			this.getStatistics()
		}
	}
</script>

<style>
	.grid-content{
		text-align: center;
		font-size: 16px;
		width: 20%;
		float: left;
	}
	.num{
		text-align: center;
		font-size: 18px;
		font-weight: bold;
	}
</style>