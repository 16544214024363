import axios from 'axios'

const apicode = function(data,that){
  if(data.code==1){
    if(data.message){
      that.$message({
        type: 'success',
        message: data.message
      });
    }
    return true;
  }else if(data.code==1001||data.code==1002){
    sessionStorage.removeItem('token');
    that.$message.error('登录失败');
    that.$router.push('/');
    return false;
  }else{
    if(data.message){
      that.$message.error(data.message);
    }else if(data.message){
      that.$message.error(data.message);
    }
    return false;
  }
}

const get = (url,params,that)=>{
  params = params || {};
  // const loading = that.$loading({
  //   lock: true,
  //   text: 'Loading',
  // });
  return new Promise((resolve)=>{
      // axiso 自带 get 和 post 方法
      axios.get(url,{
          params,
      }).then(res=>{
        var result = apicode(res.data,that);
        if(result){
          resolve(res.data.data);
        }
        // loading.close();
      }).catch(error=>{
        // loading.close();
        that.$message.error(error);
      })
  })
}
const post = (url,params,that)=>{
  const loading = that.$loading({
    lock: true,
    text: 'Loading',
  });
  params = params || {};
  return new Promise((resolve)=>{
      axios.post(url,params).then(res=>{
        var result = apicode(res.data,that);
        if(result){
          resolve(res.data.data);
        }
        loading.close();
      }).catch(error=>{
        that.$message.error(error);
        loading.close();
      })
  })
}

const arrRemove = (arr,val)=>{
  var index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr
}

const duplicateRemoval = (arr)=>{
  let arr1 = arr.filter((x,index,self)=>{
    let arrs = [];
    arr.forEach((item,i)=>{
      arrs.push(item);
    })
    return arrs.indexOf(x) === index;
  })
  return arr1
}

const tools = {
  post,
  get,
  apicode,
  arrRemove,
  duplicateRemoval,
}

export default tools;