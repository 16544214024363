<template>
	<el-card class="box-card" shadow="hover">
		<div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item style="line-height: 28px;">用户列表</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
		</div>
		<div class="text item">
			<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
			  <el-form-item label="">
			    <el-select v-model="sreachForm.status" placeholder="状态">
			      <el-option label="全部状态" value="0">全部状态</el-option>
			      <el-option label="启用" value="1">启用</el-option>
			      <el-option label="禁用" value="2">禁用</el-option>
			    </el-select>
			  </el-form-item>
				<el-form-item label="">
			    <el-select v-model="sreachForm.gender" placeholder="性别">
						<el-option label="全部" value="-1">全部</el-option>
			      <el-option label="保密" value="0">保密</el-option>
			      <el-option label="男" value="1">男</el-option>
			      <el-option label="女" value="2">女</el-option>
			    </el-select>
			  </el-form-item>
<!-- 				<el-form-item label="">
					<el-cascader
					    v-model="choosedcompanyId"
					    :options="company"
							@change="handleChangeCompany" clearable></el-cascader>
				</el-form-item> -->
			  <el-form-item label="">
			    <el-input v-model="sreachForm.words" placeholder="姓名|手机|应聘岗位|备注">
			      <template slot="prepend"><i class="el-icon-search"></i></template>
			    </el-input>
			  </el-form-item>
			  <el-form-item label="">
			    <el-button type="primary" @click="searchMember">查询</el-button>
					<el-button @click="searchNo">重置</el-button>
					<el-button type="success" @click="exportMember">导出</el-button>
			  </el-form-item>
			</el-form>
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%">
			  <el-table-column
			    prop="id"
					width="60"
			    label="ID">
			  </el-table-column>
			  <el-table-column
			    prop="realname"
			    label="姓名">
					<template slot-scope="scope">
					  <el-image v-if="scope.row.avatarUrl" style="width:60px;height: 60px;margin-right: 10px;float: left;" :src="scope.row.avatarUrl"></el-image>
						<img v-else style="width:60px;height: 60px;margin-right: 10px;float: left;" src="@/assets/imgs/noAvatar.png"/>
						<span style="line-height: 60px;">{{scope.row.realname}}</span>
					</template>
			  </el-table-column>
			  <el-table-column
			    prop="phone"
					width="120"
			    label="手机">
			  </el-table-column>
			  <el-table-column
			    prop="position"
			    label="应聘岗位"
					width="100">
			  </el-table-column>
				<el-table-column
				  prop="education"
				  label="学历"
					width="100">
				</el-table-column>
				<el-table-column
				  prop="birthday"
				  label="出生日期"
					width="100">
				</el-table-column>
				<el-table-column
				  prop="gender"
				  label="性别"
					width="50">
					<template slot-scope="scope">
						<span v-if="scope.row.gender===1">男</span>
						<span v-else-if="scope.row.gender===2">女</span>
						<span v-else>保密</span>
					</template>
				</el-table-column>
				<el-table-column
				  prop="remark"
				  label="备注">
				</el-table-column>
			  <el-table-column
			    label="操作"
			    width="210">
			    <template slot-scope="scope">
			      <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editMember(scope.row.id)">编辑</el-button>
			      <el-button v-else size="mini" type="success" plain @click="editMember(scope.row.id)">查看</el-button>
			      <el-popconfirm
			        v-show="showBtn==1"
			        confirm-button-text='好的'
			        cancel-button-text='不用了'
			        icon="el-icon-info"
			        icon-color="red"
			        @confirm="delMember(scope.row.id)" 
			        title="确定删除用户吗？"
							style="margin-left: 10px;"
			      >
			        <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
			      </el-popconfirm>
			      
			    </template>
			  </el-table-column>
			</el-table>
			<div style="margin-top: 10px;text-align: right;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="sreachForm.page"
					:page-size="sreachForm.limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
	</el-card>
</template>

<script>
	import {getCompanyList} from "@/api/company"
	import {getMemberList,delMember,exportMember} from "@/api/member"
	export default {
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				company:[],
				currentPage:1,
				total:0,
				choosedcompanyId:[],
				sreachForm:{
					limit:10,
					page:1,
					companyId:[],
					words:'',
					status:'0',
					gender:'-1',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			searchNo(){
				this.sreachForm.companyId = ''
				this.sreachForm.words = ''
				this.sreachForm.status = '0'
				this.sreachForm.page = 1
				this.getList()
			},
			handleChangeCompany(val){
				this.sreachForm.companyId = val[val.length-1]
			},
			getCompanyList(){
				getCompanyList(this.sreachForm,this).then(res=>{
					this.company = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			exportMember(){
				exportMember(this.sreachForm,this).then(res=>{
					let a = document.createElement('a')
					a.href = res.filepath
					a.click()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			searchMember(){
				this.sreachForm.page = 1
				this.getList()
			},
			getList(){
			  getMemberList(this.sreachForm,this).then(res=>{
			    this.tableData = res.data
					this.total = res.total
					this.sreachForm.page = res.current_page
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			handleSizeChange(val) {
				this.sreachForm.limit = val
				this.sreachForm.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.sreachForm.page = val
				this.getList()
			},
			editMember(id){
				this.$router.push({path:"/home/editMember",query:{id:id}})
			},
			delMember(id){
				delMember({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
					this.$message.error(err);
				})
			},
		},
		mounted () {
			this.getCompanyList()
			this.getList()
		},
	}
</script>

<style>
</style>