<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
		<span style="display: inline-block;">
			<el-breadcrumb>
				<el-breadcrumb-item style="line-height: 28px;">管理员设置</el-breadcrumb-item>
			</el-breadcrumb>
		</span>
		<router-link to='addUser' style="float:right; ">
			<el-button size="mini" type="primary">新增管理员</el-button>
		</router-link>
  </div>
  <div class="text item">
    <el-form ref="form" :model="form" inline label-width="140px">
      <el-form-item label="">
        <el-select v-model="form.status" placeholder="状态">
          <el-option label="全部状态" value="0">全部状态</el-option>
          <el-option label="启用" value="1">启用</el-option>
          <el-option label="禁用" value="2">禁用</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.words" placeholder="请输入关键词">
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="searchUser">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="id"
        label="ID">
      </el-table-column>
      <el-table-column
        prop="username"
        label="账号">
      </el-table-column>
      <el-table-column
        prop="realname"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="role_id"
        label="角色">
        <template slot-scope="scope">
          <p v-if="scope.row.role_id==1">超级管理员</p>
          <p v-if="scope.row.role_id!=1">员工</p>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="120">
        <template slot-scope="scope">
          <p v-if="scope.row.status==1">启用</p>
          <p v-if="scope.row.status!=1">禁用</p>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="160">
        <template slot-scope="scope">
          <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editUser(scope.row.id)">编辑</el-button>
          <el-button v-else size="mini" type="success" plain @click="editUser(scope.row.id)">查看</el-button>
          <el-popconfirm
            v-show="showBtn==1"
            confirm-button-text='好的'
            cancel-button-text='不用了'
            icon="el-icon-info"
            icon-color="red"
            @confirm="delUser(scope.row.id)" 
            title="确定删除管理员吗？"
						style="margin-left: 10px;"
          >
            <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
          </el-popconfirm>
          
        </template>
      </el-table-column>
    </el-table>
		<div style="margin-top: 10px;text-align: right;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.page"
				:page-size="form.limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>
  </div>
</el-card>
</template>
<script>
import {getList,delUser} from "@/api/user"
export default {
  data() {
    return {
      showBtn:sessionStorage.getItem('isEdit'),
      tableData:[],
			total:0,
      form:{
				limit:10,
        page:1,
        words:'',
        status:"0",
        token:sessionStorage.getItem('token'),
      }
    }
  },
  methods:{
    getList(){
      getList(this.form,this).then(res=>{
        this.tableData = res.data
				this.total = res.total
				this.form.page = res.current_page
      }).catch(err=>{
        this.$message.error(err);
      })
    },
    editUser(id){
      this.$router.push({path: '/home/editUser',query: {id: id}})
    },
    delUser(id){
      delUser({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
        this.getList()
      }).catch(err=>{
        this.$message.error(err);
      })
    },
    searchUser(){
      this.getList()
    },
		handleSizeChange(val) {
			this.form.limit = val
			this.form.page = 1
			this.getList()
		},
		handleCurrentChange(val) {
			this.form.page = val
			this.getList()
		}
  },
  mounted () {
    this.getList()
  }
}
</script>
<style>

</style>