import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
  state:{
    MenuTree:'',
  },
  mutations:{
    setmenuTree(state,MenuTree){
      state.MenuTree = MenuTree
    }
  }
})