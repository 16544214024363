<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
    <el-breadcrumb>
      <el-breadcrumb-item :to="path">{{title}}</el-breadcrumb-item>
      <el-breadcrumb-item>编辑岗位</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
	<div class="text item">
	  <el-form :model="form" label-width="140px">
	    <el-form-item label="岗位名称" :required="true">
	      <el-input v-if="showBtn==1" v-model="form.title" placeholder="请输入岗位名称" maxlength="150" show-word-limit></el-input>
				<span v-else>{{form.title}}</span>
	    </el-form-item>
			<el-form-item label="公司" :required="true" v-show="companyList.length>0">
				<!-- <el-select v-if="showBtn==1" v-model="form.companyId" placeholder="请选择公司">
					<el-option
						v-for="item in companyList"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select> -->
				<el-cascader
						v-if="showBtn==1"
						style="width:240px"
						v-model="form.companyList"
						:options="companyList"
						@change="handleChangeCompany" clearable></el-cascader>
				<span v-else>{{form.company}}</span>
			</el-form-item>
			<el-form inline label-width="140px">
				<el-form-item label="岗位类别" :required="true">
					<el-select v-if="showBtn==1" v-model="form.jobCate" placeholder="请选择岗位类别">
						<el-option
							v-for="item in jobCateList"
							:key="item.id"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
					<span v-else>{{form.jobCateName}}</span>
				</el-form-item>
				<el-form-item label="事业部" :required="true">
					<el-select v-if="showBtn==1" v-model="form.department" placeholder="请选择事业部">
						<el-option
							v-for="item in departmentList"
							:key="item.id"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
					<span v-else>{{form.departmentName}}</span>
				</el-form-item>
			</el-form>
			<el-form-item label="工作地点" :required="true">
				<el-cascader
						v-if="showBtn==1"
				    v-model="form.place"
				    :options="cityData"></el-cascader>
				<span v-else>{{form.place[0]}}-{{form.place[1]}}-{{form.place[2]}}</span>
			</el-form-item>
			<el-form :model="form" inline label-width="140px">
				<el-form-item label="薪资">
					<el-input v-model="form.wage" placeholder="请输入薪资" maxlength="20" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="工作年限">
					<el-input v-model="form.workingyears" placeholder="请输入工作年限" maxlength="30" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="学历">
					<el-select v-model="form.education" placeholder="请选择学历">
						<el-option label="不限" value="不限"></el-option>
						<el-option label="统招" value="统招"></el-option>
						<el-option label="职业高中" value="职业高中"></el-option>
						<el-option label="大专" value="大专"></el-option>
						<el-option label="大学本科" value="大学本科"></el-option>
						<el-option label="研究生" value="研究生"></el-option>
						<el-option label="博士" value="博士"></el-option>
						<el-option label="博士后" value="博士后"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-form inline label-width="140px">
				<el-form-item label="发布日期">
				  <el-date-picker
						v-model="form.releaseDate"
						type="date"
						placeholder="选择日期"
						value-format="timestamp">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="下线日期">
				  <el-date-picker
						v-model="form.offlineDate"
						type="date"
						placeholder="选择日期"
						value-format="timestamp">
					</el-date-picker>
					<span style="color:#afafaf;margin-left:10px">定时下线，如不选择，则不定时下线</span>
				</el-form-item>
			</el-form>
			<el-form-item label="">
			  <el-checkbox v-model="form.ishot">热招</el-checkbox>
				<el-checkbox v-if="form.cateid==3" v-model="form.ismoney">悬赏推荐</el-checkbox>
			</el-form-item>
			<el-form-item label="岗位职责" :required="true">
			  <quill-editor
						v-if="showBtn==1"
						class="ql-editor"
						ref="myTextEditor"
						style="width:800px;padding: 0;"
						:options="editorOption"
						v-model="form.responsibilities"
					></quill-editor>
				<span v-else v-html="form.responsibilities"></span>
			</el-form-item>
			<el-form-item label="任职资格" :required="true">
			  <quill-editor
						v-if="showBtn==1"
			  		class="ql-editor"
			  		ref="myTextEditor"
						:options="editorOption"
			  		style="width:800px;padding: 0;"
			  		v-model="form.qualifications"
			  	></quill-editor>
				<span v-else v-html="form.qualifications"></span>
			</el-form-item>
			<el-form-item label="状态" :required="true">
			  <el-radio-group v-if="showBtn==1" v-model="form.status">
					<el-radio :label="1">正常</el-radio>
					<el-radio :label="2">暂停</el-radio>
				</el-radio-group>
				<span v-else>{{form.status==1?'正常':'暂停'}}</span>
			</el-form-item>
			<el-form-item label="" v-if="showBtn==1">
			  <el-button @click="editPosts" type="primary">保存</el-button>
				<el-button @click="goback">返回</el-button>
			</el-form-item>
			<el-form-item label="" v-else>
				<el-button @click="goback">返回</el-button>
			</el-form-item>
	  </el-form>
	</div>
</el-card>
</template>

<script>	
	import {editPosts,getPosts} from "@/api/recruit"
	import {getJobCateList} from "@/api/jobCate"
	import {getDepartmentList} from "@/api/department"
	import {getCompanyList} from "@/api/company"
	import { Quill, quillEditor } from 'vue-quill-editor'
	import cityData from "@/assets/js/citydata.js"
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				jobCateList:[],
				departmentList:[],
				companyList:[],
				company:'',
				editorOption: { //  富文本编辑器配置
					placeholder: '请输入',
				},
				title:'',
				path:{},
				cityData:[],
				form:{
					id:'',
					title:'',
					cateid:'',
					ishot:false,
					ismoney:false,
					place:'',
					wage:'',
					workingyears:'',
					education:'',
					company:'',
					companyId:'',
					companyList:[],
					jobCateName:'',
					departmentName:'',
					department:'',
					jobCate:'',
					releaseDate:'',
					offlineDate:'',
					responsibilities:'',
					qualifications:'',
					status:1,
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods:{
			goback(){
				this.$router.go(-1)
			},
			handleChangeCompany(val){
				this.form.companyId = val[val.length-1]
			},
			getJobCateList(){
			  getJobCateList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.jobCateList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getDepartmentList(){
			  getDepartmentList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.departmentList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getCompanyList(){
				getCompanyList({token:sessionStorage.getItem('token'),isCompany:1},this).then(res=>{
					this.companyList = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			editPosts(){
				if(!this.form.title){
					this.$message.error('岗位名称不能为空')
					return false
				}
				if(!this.form.companyId){
					this.$message.error('请选择公司')
					return false
				}
				if(!this.form.department){
					this.$message.error('事业部不能为空')
					return false
				}
				if(!this.form.place){
					this.$message.error('请选择工作地点')
					return false
				}
				if(!this.form.responsibilities){
					this.$message.error('岗位职责不能为空')
					return false
				}
				if(!this.form.qualifications){
					this.$message.error('任职资格不能为空')
					return false
				}
				editPosts(this.form,this).then((res) => {
				  if(this.form.cateid==1){
				  	this.$router.push('/home/schoolPosts')
				  }else if(this.form.cateid==2){
				  	this.$router.push('/home/societyposts')
				  }else if(this.form.cateid==3){
				  	this.$router.push('/home/insidePosts')
				  }else if(this.form.cateid==4){
				  	this.$router.push('/home/shuoboPosts')
				  }
				})
			},
			getPosts(){
				getPosts({token:sessionStorage.getItem('token'),id:this.form.id},this).then(res=>{
			    this.form.title = res.title
					this.form.cateid = res.cateid
					this.form.ishot = res.ishot
					this.form.ismoney = res.ismoney
					this.form.place = res.place
					this.form.wage = res.wage
					this.form.workingyears = res.workingyears
					this.form.education = res.education
					this.form.status = res.status
					this.form.companyId = res.companyId
					this.form.companyList = res.companyList
					this.form.company = res.company
					this.form.jobCateName = res.jobCateName
					this.form.departmentName = res.departmentName
					this.form.department = res.department
					this.form.jobCate = res.jobCate
					this.form.releaseDate = res.releaseDate
					this.form.offlineDate = res.offlineDate
					this.form.responsibilities = res.responsibilities
					this.form.qualifications = res.qualifications
					if(res.cateid==1){
						this.title = '校园招聘'
						this.path = { path: 'schoolPosts' }
					}else if(res.cateid==2){
						this.title = '社会招聘'
						this.path = { path: 'societyposts' }
					}else if(res.cateid==3){
						this.title = '内部招聘'
						this.path = { path: 'insidePosts' }
					}else if(res.cateid==4){
						this.title = '硕博招聘'
						this.path = { path: 'shuoboPosts' }
					}
				}).catch(err=>{
					this.$message.error(err)
				})
			},
		},
		mounted () {
			this.form.id = this.$route.query.id
			this.cityData = cityData
			this.getCompanyList()
			this.getJobCateList()
			this.getDepartmentList()
			this.getPosts()
		}
	}
</script>

<style>
</style>