import tools from '@/utils/tools';

export async function getMemberList(params,that){
	var url = "/backend/members/getMemberList"
	return await tools.post(url,params,that)
}

export async function getMember(params,that){
	var url = "/backend/members/getMember"
	return await tools.post(url,params,that)
}

export async function delMember(params,that){
	var url = "/backend/members/delMember"
	return await tools.post(url,params,that)
}

export async function exportMember(params,that){
	var url = "/backend/members/exportMember"
	return await tools.post(url,params,that)
}

export async function setnoarchive(params,that){
	var url = "/backend/members/setnoarchive"
	return await tools.post(url,params,that)
}
export async function setfollow(params,that){
	var url = "/backend/members/setfollow"
	return await tools.post(url,params,that)
}
export async function getfollow(params,that){
	var url = "/backend/members/getfollow"
	return await tools.post(url,params,that)
}
