<template>
  <el-container>
    <div class="Login">
      <div class="LoginBox">
        <div class="BoxLes">
          <div class="LesCons">
            <h1>欢迎登录</h1>
            <h2><span>小盒BOSS后台管理</span>系统</h2>
            <div class="LesForm">
              <div class="Lis">
                <div class="Icon">
                  <img src="../assets/per.png" alt="">
                </div>
                <input type="text" v-model="form.username" placeholder="请输入账号"/>
              </div>
              <div class="Lis">
                <div class="Icon">
                  <img src="../assets/psd.png" alt="">
                </div>
                <input type="password" v-model="form.password" placeholder="请输入密码"/>
              </div>
              <div class="Lis">
                <el-radio-group v-model="form.isvip" style="margin-left: 120px;">
                  <el-radio :label="1">集团</el-radio>
                  <el-radio :label="2">公司</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="Sign" @click="onSubmit()" >登录</div>
          </div>
        </div>
        <div class="BoxRis">
          <el-carousel>
            <el-carousel-item v-for="item in swiperCons" :key="item.id">
              <img :src="item.src">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="AboutUs">
        <a target="_blank" href="http://www.qqiv.com/">腾讯企业邮箱</a>
        <a target="_blank" href="https://www.996w.com/index/Article/index.html?cid=HBzpAtNyE6&visitPower=iykwbepxar">网站建设</a>
        <a target="_blank" href="https://www.996w.com/index/Article/index.html?cid=pdrAhixuBz&visitPower=iykwbepxar">小程序开发</a>
        <a target="_blank" href="https://www.996w.com/index/Article/index.html?cid=lTGkg6twkC&visitPower=roigaigqdl">系统开发</a>
        <a target="_blank" href="https://www.996w.com/index/Article/index.html?cid=kPlJcTcBqM&visitPower=iykwbepxar">网站推广</a>
        <a target="_blank" href="https://www.996w.com/index/Articlelist/index.html?cid=iEFrgvpNe4">数据安全系统</a>
        <a target="_blank" href="https://www.996w.com">联系我们</a>
      </div>
      <div class="CopyRight">
        <div>版权所有 © 2013-2023 <a target="_blank" href="https://www.996w.com">广东企拓·九六网</a> All rights reserved.       <a target="_blank" href="https://beian.miit.gov.cn">粤ICP备17088737号</a>       公司官网：<a target="_blank" href="https://www.996w.com">996w.com</a><a style="padding-left:25px">联系电话：400-119-0035</a></div>
      </div>
    </div>
  </el-container>
</template>

<script>
import {login} from "@/api/login"
export default {
  name: 'login',
  data(){
    return {
      action:'login',
      form: {
        username: '',
        password:'',
        code:'',
				isvip:1,
      },
      swiperCons:[
        {id:1,src:require("../assets/banner1.jpg")},
        {id:2,src:require("../assets/banner2.jpg")},
        {id:3,src:require("../assets/banner3.jpg")},
      ],
      dis:false,
      errormsg:'',
      msgtype:'error',
      isLogin:false,
    }
  },
  mounted() {
    var vcode = this.getParam('vcode');
    if(vcode){
      var lang = this.getParam('lang'), username = this.getParam('username');
      sessionStorage.setItem('token', vcode) //保存服务器返回的token
      sessionStorage.setItem('mname', username)
      window.localStorage.setItem("lang", lang)
      this.$router.push('/home/index')
    }
  },
  created() {
    var that = this;
    document.onkeydown = function() {
    var key = window.event.keyCode;
      if (key == 13 && that.$route.path.length<3) {
        that.onSubmit();
      }
    }
  },
  methods:{
    getParam(paramName) {
      var paramValue = "", isFound = !1, arrSource,i,url = window.location.hash.replace('#/','');
      if (url.indexOf("?") == 0 && url.indexOf("=") > 1) { 
          arrSource = unescape(url).substring(1, url.length).split("&"), i = 0;
          while (i < arrSource.length && !isFound) arrSource[i].indexOf("=") > 0 && arrSource[i].split("=")[0].toLowerCase() == paramName.toLowerCase() && (paramValue = arrSource[i].split("=")[1], isFound = !0), i++ 
      } 
      return paramValue == "" && (paramValue = null), paramValue 
    },
    onSubmit() {
      var data = this.form;
      if(data.username==''){
        this.$message.error('请填写用户名！');
        return false;
      }
      if(data.password==''){
        this.$message.error('请填写密码！');
        return false;
      }
			login({
				username: data.username,
				password: data.password,
				isvip: data.isvip,
			},this).then(res=>{
				this.errormsg = '';
				this.dis = false;
				sessionStorage.setItem('token', res.sessionId)
				sessionStorage.setItem('name', res.username)
				sessionStorage.setItem('roleid', res.roleid)
				sessionStorage.setItem('isvip', res.isvip)
				sessionStorage.setItem('isEdit', res.isEdit)
        sessionStorage.setItem('delTalent', res.delTalent)
				this.$router.push('/home/statistics')
			}).catch(err=>{
				this.$message.error(err);
			})
    },
    mustLogin(message){
      this.$confirm(message, '提示', {
        confirmButtonText: '继续登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isLogin=true
        this.onSubmit()
      }).catch(() => {
        this.errormsg = '';
        this.dis = false;
        this.$message({
          type: 'info',
          message: '已取消'
        });          
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body,h1,h2{margin: 0;}
div{font-family: "思源黑体";}
.Login{width: 100vw;height: 100vh;background-image: url("../assets/bg.jpg");background-size: auto 100%;background-position: center center;display: flex;align-items: center;justify-content: center;position:relative;}
.Login .LoginBox{width: 1280px;height: 650px;background-color: #fff;border-radius: 8px;box-shadow: 0 0 20px 0px rgba(0,0,0,.1);padding: 10px;box-sizing: border-box;display:flex;}
.Login .LoginBox .BoxLes{width: 600px;text-align:left;}
.Login .LoginBox .BoxLes .LesCons{display: flex;width: 410px;margin: auto;flex-direction: column;}
.Login .LoginBox .BoxLes .LesCons h1{font-size: 36px;line-height:36px;padding:90px 0 20px;}
.Login .LoginBox .BoxLes .LesCons h2{font-size: 32px;line-height:32px;}
.Login .LoginBox .BoxLes .LesCons h2 span{color: #175ceb;}
.Login .LoginBox .BoxLes .LesCons .LesForm{margin: 50px 0 55px;}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis{width: 100%;line-height: 50px;box-sizing: border-box;height: 50px;display: flex;overflow:hidden;position:relative;margin-bottom:25px;}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis .Icon{width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;position:absolute;}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis .Icon img{display: block}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis input{outline:0;border: 1px solid  #ccc;box-sizing:border-box;padding:0;width:100%;border-radius: 6px;padding-left:50px;}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis input:focus{border:1px solid #175ceb;}
.Login .LoginBox .BoxLes .LesCons .LesForm .Lis input::placeholder{font-size:16px;}
.Login .LoginBox .BoxLes .LesCons .Sign{width:100%;background:#175ceb;color:#fff;line-height:50px;text-align:center;border-radius:25px;cursor:pointer;} 
.Login .LoginBox .BoxRis{width:660px;height:100%;box-sizing: border-box;border-top-right-radius:6px;border-bottom-right-radius:6px;overflow:hidden;}
.Login .LoginBox .BoxRis .el-carousel,.Login .LoginBox .BoxRis .el-carousel .el-carousel__container{height:100%;}




.Login .AboutUs{position:absolute;bottom:70px;line-height:45px;}
.Login .AboutUs a{padding: 0 7px;color:#666;font-size:14px;}





.Login .CopyRight{position:absolute;bottom:0;width:100%;height:70px;background:#f8f8f8;text-align: center;}
.Login .CopyRight>div{line-height:70px;font-size:14px;color:#666;}
.Login .CopyRight>div a{color:#666;text-decoration:none;}
.Login .CopyRight>div a:nth-of-type(2){padding:0 25px}
</style>
