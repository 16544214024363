<template>
	<el-card class="box-card" shadow="hover">
		<div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item style="line-height: 28px;">申请管理</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
		</div>
		<div class="text item">
			<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
				<el-form-item label="岗位类别" :required="true">
					<el-select v-model="sreachForm.jobCate" placeholder="请选择岗位类别">
						<el-option label="全部" value="">全部</el-option>
						<el-option
							v-for="item in jobCateList"
							:key="item.id"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="事业部" :required="true">
					<el-select v-model="sreachForm.department" placeholder="请选择事业部">
						<el-option label="全部" value="">全部</el-option>
						<el-option
							v-for="item in departmentList"
							:key="item.id"
							:label="item.title"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
			  <el-form-item label=" ">
			    <el-select v-model="sreachForm.status" placeholder="状态">
			      <el-option label="全部状态" value="0">全部状态</el-option>
			      <el-option label="申请中" value="1">申请中</el-option>
			      <el-option label="发送考题" value="2">发送考题</el-option>
						<el-option label="通知面试" value="3">通知面试</el-option>
						<el-option label="通过" value="4">通过</el-option>
						<el-option label="拒绝" value="9">拒绝</el-option>
			    </el-select>
			  </el-form-item>
				<el-form-item label="">
					<!-- <el-select v-model="sreachForm.companyId" placeholder="请选择公司">
						<el-option label="全部" value="">全部</el-option>
						<el-option
							v-for="item in company"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select> -->
					<el-cascader
					    v-model="choosedcompanyId"
					    :options="company"
							@change="handleChangeCompany" clearable></el-cascader>
				</el-form-item>
			  <el-form-item label="">
			    <el-input v-model="sreachForm.words" placeholder="岗位ID|岗位|姓名|电话">
			      <template slot="prepend"><i class="el-icon-search"></i></template>
			    </el-input>
			  </el-form-item>
			  <el-form-item label="">
			    <el-button type="primary" @click="searchApply">查询</el-button>
					<el-button @click="searchNo">重置</el-button>
					<el-button type="success" @click="exportApply">导出</el-button>
			  </el-form-item>
			</el-form>
			<el-tabs v-model="sreachForm.cateid" @tab-click="handleClick">
				<el-tab-pane label="全部" name=""></el-tab-pane>
				<el-tab-pane label="校园招聘" name="1"></el-tab-pane>
				<el-tab-pane label="社会招聘" name="2"></el-tab-pane>
				<el-tab-pane label="内部招聘" name="3"></el-tab-pane>
				<el-tab-pane label="硕博招聘" name="4"></el-tab-pane>
			</el-tabs>
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column
					type="selection"
					width="55">
				</el-table-column>
			  <el-table-column
			    prop="paid"
					width="80"
			    label="ID">
			  </el-table-column>
				<el-table-column
				  prop="realname"
				  label="姓名"
					width="200">
					<template slot-scope="scope">
						<el-image v-if="scope.row.avatarUrl" style="width:60px;height: 60px;margin-right: 10px;float: left;" :src="scope.row.avatarUrl"></el-image>
						<img v-else style="width:60px;height: 60px;margin-right: 10px;float: left;" src="@/assets/imgs/noAvatar.png"/>
						<div>
							<p style="margin:0;">{{scope.row.realname}}</p>
							<p style="margin:0;">{{scope.row.phone}}</p>
							<p style="margin:0;">{{scope.row.email}}</p>
						</div>
					</template>
				</el-table-column>
			  <el-table-column
			    prop="title"
			    label="岗位名称">
			  </el-table-column>
			  <el-table-column
			    prop="jobCate"
			    label="岗位类别"
					width="90">
			  </el-table-column>
				<el-table-column
				  prop="department"
				  label="事业部"
					width="80">
				</el-table-column>
			  <el-table-column
			    prop="place"
			    label="工作地点"
					width="180">
			  </el-table-column>
				<el-table-column
				  prop="applyTime"
				  label="申请时间"
					width="140">
				</el-table-column>
				<el-table-column
				  prop="questionTime"
				  label="发送考题时间"
					width="140">
				</el-table-column>
				<el-table-column
				  prop="noticeTime"
				  label="通知面试时间"
					width="140">
				</el-table-column>
				<el-table-column
				  prop="passTime"
				  label="通过面试时间"
					width="140">
				</el-table-column>
			  <el-table-column
			    label="状态"
			    width="70">
			    <template slot-scope="scope">
			      <p v-if="scope.row.paStatus==1">申请中</p>
			      <p v-else-if="scope.row.paStatus==2">发送考题</p>
						<p v-else-if="scope.row.paStatus==3">通知面试</p>
						<p v-else-if="scope.row.paStatus==4">通过</p>
						<p v-else-if="scope.row.paStatus==9">拒绝</p>
			    </template>
			  </el-table-column>
			  <el-table-column
			    label="操作"
			    width="210">
			    <template slot-scope="scope">
			      <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editApply(scope.row.paid)">编辑</el-button>
			      <el-button v-else size="mini" type="success" plain @click="editApply(scope.row.paid)">查看</el-button>
						<el-button v-if="showBtn==1" size="mini" type="warning" plain @click="showArchive(scope.row.mid)">归档</el-button>
			      <el-popconfirm
			        v-show="showBtn==1"
			        confirm-button-text='好的'
			        cancel-button-text='不用了'
			        icon="el-icon-info"
			        icon-color="red"
			        @confirm="delApply(scope.row.paid)" 
			        title="确定删除申请吗？"
							style="margin-left: 10px;"
			      >
			        <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
			      </el-popconfirm>
			      
			    </template>
			  </el-table-column>
			</el-table>
			<div style="margin-top: 10px;text-align: right;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="sreachForm.page"
					:page-size="sreachForm.limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="归档" :visible.sync="archivebox">
			<el-form :model="archiveForm" label-width="120px">
				<el-form-item label="备注">
					<el-input v-model="archiveForm.remark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="setarchive">确定</el-button>
					<el-button @click="archivebox=false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-card>
</template>

<script>
	import {getApplyList,delApply,exportApply,setarchive} from "@/api/apply"
	import {getJobCateList} from "@/api/jobCate"
	import {getDepartmentList} from "@/api/department"
	import {getCompanyList} from "@/api/company"
	export default {
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				company:[],
				jobCateList:[],
				departmentList:[],
				currentPage:1,
				total:0,
				choosedcompanyId:[],
				multipleSelection:[],
				sreachForm:{
					ids:[],
					jobCate:'',
					department:'',
					cateid:"",
					limit:10,
					page:1,
					companyId:[],
					words:'',
					status:'0',
					token:sessionStorage.getItem('token'),
				},
				archivebox:false,
				archiveForm:{
					mid:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			setarchive(){
				setarchive(this.archiveForm,this).then(res=>{
			    this.archivebox = false
					this.getList()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			showArchive(id){
				this.archivebox = true
				this.archiveForm.mid = id
				this.archiveForm.remark = ''
			},
			handleSelectionChange(val) {
        this.multipleSelection = val;
				let ids = []
				for(var i in val){
					ids.push(val[i].id)
				}
				this.sreachForm.ids = ids
      },
			searchNo(){
				this.sreachForm.companyId = ''
				this.sreachForm.jobCate = ''
				this.sreachForm.department = ''
				this.sreachForm.words = ''
				this.sreachForm.status = '0'
				this.choosedcompanyId = []
				this.sreachForm.page=1
				this.getList()
			},
			handleChangeCompany(val){
				this.sreachForm.companyId = val[val.length-1]
			},
			exportApply(){
				exportApply(this.sreachForm,this).then(res=>{
					let a = document.createElement('a')
					a.href = res.filepath
					a.click()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			getCompanyList(){
				getCompanyList({token:sessionStorage.getItem('token'),isCompany:1},this).then(res=>{
					this.company = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			getJobCateList(){
			  getJobCateList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.jobCateList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getDepartmentList(){
			  getDepartmentList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.departmentList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getList(){
			  getApplyList(this.sreachForm,this).then(res=>{
			    this.tableData = res.data
					this.total = res.total
					this.sreachForm.page = res.current_page
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			searchApply(){
				this.sreachForm.page = 1
				this.getList()
			},
			handleSizeChange(val) {
				this.sreachForm.limit = val
				this.sreachForm.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.sreachForm.page = val
				this.getList()
			},
			handleClick(){
				this.sreachForm.page = 1
				this.getList()
			},
			editApply(id){
				this.$router.push({path:"/home/editApply",query:{id:id}})
			},
			delApply(id){
				delApply({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
		},
		mounted () {
			if(this.$route.query.postid){
				this.sreachForm.words = this.$route.query.postid
			}
			this.getCompanyList()
			this.getJobCateList()
			this.getDepartmentList()
			this.getList()
		}
	}
</script>

<style>
</style>