<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: 'member' }">会员列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑会员</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
	<div class="text item">
		<p class="dividerLine">基础信息</p>
		<el-descriptions title="" :column="3" border>
		    <el-descriptions-item label="姓名">{{form.data.realname}}</el-descriptions-item>
		    <el-descriptions-item label="头像">
					<el-image v-if="form.data.avatarUrl" style="width:60px;height: 60px;" :src="form.data.avatarUrl"></el-image>
					<img v-else style="width:60px;height: 60px;" src="@/assets/imgs/noAvatar.png"/>
				</el-descriptions-item>
		    <el-descriptions-item label="性别">
					<span v-if="form.data.gender===0">保密</span>
					<span v-else-if="form.data.gender===1">男</span>
					<span v-else-if="form.data.gender===2">女</span>
				</el-descriptions-item>
		    <el-descriptions-item label="手机号码">{{form.data.phone}}</el-descriptions-item>
		    <el-descriptions-item label="邮箱">{{form.data.email}}</el-descriptions-item>
				<el-descriptions-item label="籍贯">{{form.data.native_place}}</el-descriptions-item>
				<el-descriptions-item label="微信号">{{form.data.wxcode}}</el-descriptions-item>
				<el-descriptions-item label="证件号码">{{form.data.identification}}</el-descriptions-item>
				<el-descriptions-item label="出生日期">{{form.data.birthday}}</el-descriptions-item>
				<el-descriptions-item label="身高">{{form.data.high}}</el-descriptions-item>
				<el-descriptions-item label="政治面貌">{{form.data.political}}</el-descriptions-item>
				<el-descriptions-item label="婚姻状况">
					<span v-if="form.data.marriage===0">未知</span>
					<span v-else-if="form.data.marriage===1">已婚</span>
					<span v-else-if="form.data.marriage===2">未婚</span>
				</el-descriptions-item>
				<el-descriptions-item label="兴趣爱好">{{form.data.hobby}}</el-descriptions-item>
				<el-descriptions-item label="户口所在地">{{form.data.replace}}</el-descriptions-item>
				<el-descriptions-item label="现居住地">{{form.data.place}}</el-descriptions-item>
				<el-descriptions-item label="学历">{{form.data.education}}</el-descriptions-item>
				<el-descriptions-item label="工作经验">{{form.data.experience}}</el-descriptions-item>
				<el-descriptions-item label="归档状况">
					<span v-if="form.data.archive===1">已归档</span>
					<span v-else>未归档</span>
				</el-descriptions-item>
				<el-descriptions-item label="备注">{{form.data.remark}}</el-descriptions-item>
		</el-descriptions>
		<p class="dividerLine">求职意向</p>
			<el-descriptions title="" :column="3" border>
			    <el-descriptions-item label="应聘岗位">{{form.intention.position}}</el-descriptions-item>
			    <el-descriptions-item label="期望月薪">{{form.intention.wages}}</el-descriptions-item>
			    <el-descriptions-item label="期望工作地区">{{form.intention.place}}</el-descriptions-item>
			    <el-descriptions-item label="期待从事职业">{{form.intention.occupation}}</el-descriptions-item>
			    <el-descriptions-item label="期望从事行业">{{form.intention.industry}}</el-descriptions-item>
					<el-descriptions-item label="目前状况">{{form.intention.situation}}</el-descriptions-item>
					<el-descriptions-item label="到岗时间">{{form.intention.arrivalTime}}</el-descriptions-item>
					<el-descriptions-item label="工作性质">{{form.intention.nature}}</el-descriptions-item>
			</el-descriptions>
		<p class="dividerLine">自我评述</p>
		<el-form :model="form" label-width="140px">
			{{form.comment}}
		</el-form>
		<p class="dividerLine">教育经历</p>
		<el-form :model="form" label-width="140px">
			<el-table
				:data="form.educationalData"
				border
				style="width: 100%">
				<el-table-column
					prop="educationalTime"
					label="就学时间"
					width="180">
				</el-table-column>
				<el-table-column
					prop="school"
					label="学校名称"
					width="200">
				</el-table-column>
				<el-table-column
					prop="degree"
					label="学位/学历"
					width="140">
				</el-table-column>
				<el-table-column
					prop="major"
					label="就学专业"
					width="160">
				</el-table-column>
				<el-table-column
					prop="professional"
					label="专业描述">
				</el-table-column>
			</el-table>
		</el-form>
		<p class="dividerLine">培训经历</p>
		<el-form :model="form" label-width="140px">
			<el-table
				:data="form.trainingData"
				border
				style="width: 100%">
				<el-table-column
					prop="trainingTime"
					label="培训时间"
					width="180">
				</el-table-column>
				<el-table-column
					prop="motto"
					label="校训机构/学校"
					width="200">
				</el-table-column>
				<el-table-column
					prop="remark"
					label="培训描述">
				</el-table-column>
				<el-table-column
					prop="represent"
					label="专业描述">
				</el-table-column>
			</el-table>
		</el-form>
		<p class="dividerLine">工作经历</p>
		<el-form :model="form" label-width="140px">
			<el-table
				:data="form.workingData"
				border
				style="width: 100%">
				<el-table-column
					prop="workingTime"
					label="工作时间"
					width="180">
				</el-table-column>
				<el-table-column
					prop="company"
					label="公司名称"
					width="140">
				</el-table-column>
				<el-table-column
					prop="position"
					label="担任职位"
					width="140">
				</el-table-column>
				<el-table-column
					prop="size"
					label="公司规模"
					width="140">
				</el-table-column>
				<el-table-column
					prop="industry"
					label="行业类别"
					width="120">
				</el-table-column>
				<el-table-column
					prop="nature"
					label="企业性质"
					width="120">
				</el-table-column>
				<el-table-column
					prop="content"
					label="工作内容">
				</el-table-column>
			</el-table>
		</el-form>
		<p class="dividerLine">项目经验</p>
		<el-form :model="form" label-width="140px">
			<el-table
				:data="form.workingData"
				border
				style="width: 100%">
				<el-table-column
					prop="workingTime"
					label="工作时间"
					width="180">
				</el-table-column>
				<el-table-column
					prop="company"
					label="公司名称"
					width="140">
				</el-table-column>
				<el-table-column
					prop="position"
					label="担任职位"
					width="140">
				</el-table-column>
				<el-table-column
					prop="size"
					label="公司规模"
					width="140">
				</el-table-column>
				<el-table-column
					prop="industry"
					label="行业类别"
					width="120">
				</el-table-column>
				<el-table-column
					prop="nature"
					label="企业性质"
					width="120">
				</el-table-column>
				<el-table-column
					prop="content"
					label="工作内容">
				</el-table-column>
			</el-table>
		</el-form>
		<p class="dividerLine">其他信息</p>
		<el-form :model="form" label-width="140px">
			{{form.data.other}}
		</el-form>
		<p class="dividerLine">附件信息</p>
		<el-form :model="form" label-width="140px">
			<a v-if="form.data.file" class="el-button" target="_blank" :href="form.data.file">查看简历</a>
			<p v-else style="text-align: center;">暂无附件</p>
		</el-form>
		<p class="dividerLine">资质证书</p>
		<el-form :model="form" label-width="140px">
			<a v-if="form.data.wfile" class="el-button" target="_blank" :href="form.data.wfile">查看资质证书</a>
			<p v-else style="text-align: center;">暂无附件</p>
		</el-form>
		<el-form :model="form" label-width="140px">
			<el-button @click="goback">返回</el-button>
		</el-form>
	</div>
</el-card>
</template>

<script>	
	import {editMember,getMember} from "@/api/member"
	import { Quill, quillEditor } from 'vue-quill-editor'
	import cityData from "@/assets/js/citydata.js"
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				companyList:[],
				editorOption: { //  富文本编辑器配置
					placeholder: '请输入',
				},
				title:'',
				path:{},
				cityData:[],
				politicsOptions: [
					"中共党员",
					"中共预备党员",
					"共青团员",
					"民革党员",
					"民盟盟员",
					"民建会员",
					"民进会员",
					"农工党党员",
					"致公党党员",
					"九三学社社员",
					"台盟盟员",
					"无党派人士",
					"群众",
				],
				marriageOptions: ["保密", "已婚", "未婚"],
				eduOptions: [
					"小学",
					"初中",
					"高中",
					"职业高中",
					"大专",
					"大学本科",
					"研究生",
					"博士",
					"博士后",
				],
				expOptions: ["1年以内", "1~3年", "3~5年", "5年以上"],
				form:{
					id:'',
					data:[],
					educationalData:[],
					intention:[],
					projectData:[],
					trainingData:[],
					workingData:[],
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods:{
			goback(){
				this.$router.go(-1)
			},
			editMember(){
				if(!this.form.title){
					this.$message.error('岗位名称不能为空')
					return false
				}
				if(!this.form.companyId){
					this.$message.error('请选择公司')
					return false
				}
				if(!this.form.department){
					this.$message.error('事业部不能为空')
					return false
				}
				if(!this.form.place){
					this.$message.error('请选择工作地点')
					return false
				}
				if(!this.form.responsibilities){
					this.$message.error('岗位职责不能为空')
					return false
				}
				if(!this.form.qualifications){
					this.$message.error('任职资格不能为空')
					return false
				}
				editPosts(this.form,this).then((res) => {
				  if(this.form.cateid==1){
				  	this.$router.push('/home/schoolPosts')
				  }else if(this.form.cateid==2){
				  	this.$router.push('/home/societyposts')
				  }else if(this.form.cateid==3){
				  	this.$router.push('/home/insidePosts')
				  }else if(this.form.cateid==4){
				  	this.$router.push('/home/shuoboPosts')
				  }
				})
			},
			getMember(){
				getMember({token:sessionStorage.getItem('token'),id:this.form.id},this).then(res=>{
			    this.form.data = res.data
					this.form.educationalData = res.educational
					this.form.intention = res.intention
					this.form.projectData = res.project
					this.form.trainingData = res.training
					this.form.workingData = res.working
				}).catch(err=>{
					this.$message.error(err)
				})
			},
		},
		mounted () {
			this.form.id = this.$route.query.id
			this.cityData = cityData
			this.getMember()
		}
	}
</script>

<style>
</style>