import tools from '@/utils/tools';

export async function getApplyList(params,that){
	var url = "/backend/apply/list"
	return await tools.post(url,params,that)
}

export async function getApply(params,that){
	var url = "/backend/apply/getApply"
	return await tools.post(url,params,that)
}

export async function editApply(params,that){
	var url = "/backend/apply/editApply"
	return await tools.post(url,params,that)
}

export async function sendQuestions(params,that){
	var url = "/backend/apply/sendQuestions"
	return await tools.post(url,params,that)
}

export async function sendNotice(params,that){
	var url = "/backend/apply/sendNotice"
	return await tools.post(url,params,that)
}

export async function sendPass(params,that){
	var url = "/backend/apply/sendPass"
	return await tools.post(url,params,that)
}

export async function nosendPass(params,that){
	var url = "/backend/apply/nosendPass"
	return await tools.post(url,params,that)
}

export async function delApply(params,that){
	var url = "/backend/apply/delApply"
	return await tools.post(url,params,that)
}

export async function exportApply(params,that){
	var url = "/backend/apply/exportApply"
	return await tools.post(url,params,that)
}

export async function getApplyNum(params,that){
	var url = "/backend/apply/getApplyNum"
	return await tools.get(url,params,that)
}

export async function delcode(params,that){
	var url = "/backend/apply/delcode"
	return await tools.get(url,params,that)
}

export async function getPointList(params,that){
	var url = "/backend/apply/getPointList"
	return await tools.post(url,params,that)
}

export async function createLetter(params,that){
	var url = "/backend/apply/createLetter"
	return await tools.post(url,params,that)
}

export async function delletter(params,that){
	var url = "/backend/apply/delletter"
	return await tools.post(url,params,that)
}

export async function setarchive(params,that){
	var url = "/backend/apply/setarchive"
	return await tools.post(url,params,that)
}