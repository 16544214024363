import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import SIdentify from './components/identify.vue'
import axios from 'axios'
import store from './store'
import router from './router'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as echarts from 'echarts'

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(SIdentify);
Vue.use(VueQuillEditor);
Vue.prototype.$echarts = echarts

//axios
axios.defaults.timeout = 5000 // 请求超时
axios.defaults.baseURL = '/'  // api 即上面 vue.config.js 中配置的地址

// 导航守卫
router.beforeEach((to,from,next)=>{
  let token = sessionStorage.getItem('token');
  if(token){
    //登录了 跳回登录页 则回跳
    if(to.path === '/'){
      if(token&&typeof(token) !== undefined){
        next({path:'/home/index'});
      }else{
        next();
      }
    }else{
      //正常登录
      next();
    }

  }else {
    //没有登录，去跳转登录页
    if(to.path === '/'){
      next();
    }else {
      next({
        path:'/'
      });
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store:store,
  template: '<App/>'
}).$mount('#app')
