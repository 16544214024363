import tools from '@/utils/tools';

export async function getCompanyList(params,that){
	var url = "/backend/company/list"
	return await tools.post(url,params,that)
}

export async function saveCompany(params,that){
	var url = "/backend/company/save"
	return await tools.post(url,params,that)
}

export async function delCompany(params,that){
	var url = "/backend/company/del"
	return await tools.post(url,params,that)
}

//获取员工接口
export async function getStaff(params,that){
	var url = "/backend/company/getStaff"
	return await tools.post(url,params,that)
}

//添加员工接口
export async function addStaff(params,that){
	var url = "/backend/company/addStaff"
	return await tools.post(url,params,that)
}

//编辑员工接口
export async function editStaff(params,that){
	var url = "/backend/company/editStaff"
	return await tools.post(url,params,that)
}

//编辑员工接口
export async function delStaff(params,that){
	var url = "/backend/company/delStaff"
	return await tools.post(url,params,that)
}

export async function getStaffList(params,that){
	var url = "/backend/company/staffList"
	return await tools.post(url,params,that)
}

//获取权限接口
export async function getPowerList(params,that){
	var url = "/backend/company/getPowerList"
	return await tools.post(url,params,that)
}