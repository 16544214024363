<template>
	<el-card class="box-card" shadow="hover">
	  <div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item style="line-height: 28px;">员工管理</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
			<router-link to='addStaff' style="float:right; ">
				<el-button size="mini" type="primary">新增员工</el-button>
			</router-link>
	  </div>
	  <div class="text item">
	    <el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
	      <el-form-item label="">
	        <el-select v-model="sreachForm.status" placeholder="状态">
	          <el-option label="全部状态" value="0">全部状态</el-option>
	          <el-option label="启用" value="1">启用</el-option>
	          <el-option label="禁用" value="2">禁用</el-option>
	        </el-select>
	      </el-form-item>
				<el-form-item label="">
				  <el-cascader
				  	v-model="sreachForm.company"
				  	:options="company"
				  	:props="{ checkStrictly: true }"
				  	clearable></el-cascader>
				</el-form-item>
	      <el-form-item label="">
	        <el-input v-model="sreachForm.words" placeholder="请输入关键词">
	          <template slot="prepend"><i class="el-icon-search"></i></template>
	        </el-input>
	      </el-form-item>
	      <el-form-item label="">
	        <el-button type="primary" @click="searchStaff">查询</el-button>
					<el-button @click="searchNo">重置</el-button>
	      </el-form-item>
	    </el-form>
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%">
			  <el-table-column
			    prop="id"
					width="80"
			    label="ID">
			  </el-table-column>
			  <el-table-column
			    prop="username"
			    label="账号">
			  </el-table-column>
			  <el-table-column
			    prop="realname"
			    label="姓名">
			  </el-table-column>
			  <el-table-column
			    prop="company"
			    label="部门">
			  </el-table-column>
			  <el-table-column
			    label="状态"
			    width="100">
			    <template slot-scope="scope">
			      <p v-if="scope.row.status==1">启用</p>
			      <p v-if="scope.row.status!=1">禁用</p>
			    </template>
			  </el-table-column>
			  <el-table-column
			    label="操作"
			    width="160">
			    <template slot-scope="scope">
			      <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editStaff(scope.row.id)">编辑</el-button>
			      <el-button v-else size="mini" type="success" plain @click="editStaff(scope.row.id)">查看</el-button>
			      <el-popconfirm
			        v-show="showBtn==1"
			        confirm-button-text='好的'
			        cancel-button-text='不用了'
			        icon="el-icon-info"
			        icon-color="red"
			        @confirm="delStaff(scope.row.id)" 
			        title="确定删除员工吗？"
							style="margin-left: 10px;"
			      >
			        <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
			      </el-popconfirm>
			      
			    </template>
			  </el-table-column>
			</el-table>
			<div style="margin-top: 10px;text-align: right;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="sreachForm.page"
					:page-size="sreachForm.limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
	  </div>
	</el-card>
</template>

<script>
	import {getCompanyList,getStaffList,delStaff} from "@/api/company"
	export default {
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				company:[],
				currentPage:1,
				total:0,
				sreachForm:{
					limit:10,
					company:[],
					page:1,
					words:'',
					status:'0',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			searchNo(){
				this.sreachForm.company = []
				this.sreachForm.words = ''
				this.sreachForm.status = '0'
				this.sreachForm.page = 1
				this.getList()
			},
			getCompanyList(){
				getCompanyList(this.sreachForm,this).then(res=>{
					this.company = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			getList(){
			  getStaffList(this.sreachForm,this).then(res=>{
			    this.tableData = res.data
					this.total = res.total
					this.sreachForm.page = res.current_page
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			searchStaff(){
				this.sreachForm.page = 1
				this.getList()
			},
			handleSizeChange(val) {
				this.sreachForm.limit = val
				this.sreachForm.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.sreachForm.page = val
				this.getList()
			},
			editStaff(id){
				this.$router.push({path:"/home/editStaff",query:{id:id}})
			},
			delStaff(id){
				delStaff({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
		},
		mounted () {
			this.getCompanyList()
			this.getList()
		},
	}
</script>

<style>
</style>