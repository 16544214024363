<template>
<el-card class="box-card" shadow="hover">
	<div slot="header" class="clearfix" style="position:relative">
		<span style="display: inline-block;">
			<el-breadcrumb>
				<el-breadcrumb-item style="line-height: 28px;">社会招聘</el-breadcrumb-item>
			</el-breadcrumb>
		</span>
		<router-link to='addPosts?cateid=2' style="float:right;">
			<el-button size="mini" type="primary">新增岗位</el-button>
		</router-link>
	</div>
	<div class="text item">
		<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
			<el-form-item label="岗位类别" :required="true">
				<el-select v-model="sreachForm.jobCate" placeholder="请选择岗位类别">
					<el-option label="全部" value="">全部</el-option>
					<el-option
						v-for="item in jobCateList"
						:key="item.id"
						:label="item.title"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="事业部" :required="true">
				<el-select v-model="sreachForm.department" placeholder="请选择事业部">
					<el-option label="全部" value="">全部</el-option>
					<el-option
						v-for="item in departmentList"
						:key="item.id"
						:label="item.title"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
		  <el-form-item label=" ">
		    <el-select v-model="sreachForm.status" placeholder="状态">
		      <el-option label="全部状态" value="0">全部状态</el-option>
		      <el-option label="启用" value="1">启用</el-option>
		      <el-option label="暂停" value="2">暂停</el-option>
		    </el-select>
		  </el-form-item>
			<el-form-item label="">
				<!-- <el-select v-model="sreachForm.companyId" placeholder="请选择公司">
					<el-option label="全部" value="">全部</el-option>
					<el-option
						v-for="item in company"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select> -->
				<el-cascader
				    v-model="choosedcompanyId"
				    :options="company"
						@change="handleChangeCompany" clearable></el-cascader>
			</el-form-item>
		  <el-form-item label="">
		    <el-input v-model="sreachForm.words" placeholder="岗位|事业部|工作地点">
		      <template slot="prepend"><i class="el-icon-search"></i></template>
		    </el-input>
		  </el-form-item>
		  <el-form-item label="">
		    <el-button type="primary" @click="searchPosts">查询</el-button>
				<el-button @click="searchNo">重置</el-button>
		  </el-form-item>
		</el-form>
		<el-table
		  :data="tableData"
		  border
		  style="width: 100%">
		  <el-table-column
		    prop="id"
				width="80"
		    label="ID">
		  </el-table-column>
		  <el-table-column
		    prop="title"
		    label="岗位名称">
		  </el-table-column>
		  <el-table-column
		    prop="jobCate"
		    label="岗位类别"
				width="80">
		  </el-table-column>
			<el-table-column
			  prop="department"
			  label="事业部"
				width="80">
			</el-table-column>
		  <el-table-column
		    prop="place"
		    label="工作地点"
				width="180">
		  </el-table-column>
			<el-table-column
		    prop="wage"
		    label="	薪资"
				width="100">
		  </el-table-column>
			<el-table-column
		    prop="workingyears"
		    label="工作年限"
				width="100">
		  </el-table-column>
			<el-table-column
		    prop="education"
		    label="学历"
				width="100">
		  </el-table-column>
			<el-table-column
			  prop="releaseDate"
			  label="发布时间"
				width="100">
			</el-table-column>
		  <el-table-column
		    label="状态"
		    width="70">
		    <template slot-scope="scope">
					<el-switch
						@change = "changstatus(scope.row.id,scope.row.statusvalue)"
						v-model="scope.row.statusvalue">
					</el-switch>
		    </template>
		  </el-table-column>
		  <el-table-column
		    label="操作"
		    width="260">
		    <template slot-scope="scope">
		      <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editPosts(scope.row.id)">编辑</el-button>
		      <el-button v-else size="mini" type="success" plain @click="editPosts(scope.row.id)">查看</el-button>
					<el-button size="mini" plain @click="goApply(scope.row.id)">申请列表({{scope.row.applycount}})</el-button>
		      <el-popconfirm
		        v-show="showBtn==1"
		        confirm-button-text='好的'
		        cancel-button-text='不用了'
		        icon="el-icon-info"
		        icon-color="red"
		        @confirm="delPosts(scope.row.id)" 
		        title="确定删除员工吗？"
						style="margin-left: 10px;"
		      >
		        <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
		      </el-popconfirm>
		      
		    </template>
		  </el-table-column>
		</el-table>
		<div style="margin-top: 10px;text-align: right;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-size="sreachForm.limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>
	</div>
</el-card>
</template>

<script>
	import {getPostsList,delPosts,changPostStatus} from "@/api/recruit"
	import {getJobCateList} from "@/api/jobCate"
	import {getDepartmentList} from "@/api/department"
	import {getCompanyList} from "@/api/company"
	export default {
		data() {
			return {
				title:'',
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				company:[],
				currentPage:1,
				total:0,
				jobCateList:[],
				departmentList:[],
				choosedcompanyId:[],
				sreachForm:{
					jobCate:'',
					department:'',
					cateid:2,
					page:1,
					limit:10,
					companyId:'',
					words:'',
					status:'0',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			changstatus(id,status){
				changPostStatus({token:sessionStorage.getItem('token'),status:status,id:id},this).then(res=>{
					
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			searchNo(){
				this.sreachForm.companyId = ''
				this.sreachForm.jobCate = ''
				this.sreachForm.department = ''
				this.sreachForm.words = ''
				this.sreachForm.status = '0'
				this.choosedcompanyId = []
				this.sreachForm.page=1
				this.getList()
			},
			handleChangeCompany(val){
				this.sreachForm.companyId = val[val.length-1]
			},
			getCompanyList(){
				getCompanyList({token:sessionStorage.getItem('token'),isCompany:1},this).then(res=>{
					this.company = res
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			getJobCateList(){
			  getJobCateList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.jobCateList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getDepartmentList(){
			  getDepartmentList({token:sessionStorage.getItem('token')},this).then(res=>{
			    this.departmentList = res
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			getList(){
			  getPostsList(this.sreachForm,this).then(res=>{
			    this.tableData = res.data
					this.total = res.total
					this.currentPage = res.current_page
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			searchPosts(){
				this.sreachForm.page = 1
				this.getList()
			},
			handleSizeChange(val) {
				this.sreachForm.limit = val
				this.sreachForm.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.sreachForm.page = val
				this.getList()
			},
			editPosts(id){
				this.$router.push({path:"/home/editPosts",query:{id:id,cateid:2}})
			},
			delPosts(id){
				delPosts({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
			goApply(postid){
				this.$router.push({path:"/home/apply",query:{postid:postid}})
			},
		},
		mounted () {
			this.getCompanyList()
			this.getJobCateList()
			this.getDepartmentList()
			this.getList()
		}
	}
</script>

<style>
</style>