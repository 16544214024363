<template>
  <el-submenu v-if="menu.child && menu.child.length >= 1" :index='menu.path'>
    <template slot="title">
      <i :class="menu.icon"></i>
      <span slot="title">{{menu.name}}</span>
    </template>
    <el-menu-item-group >
      <div>
      <el-menu-item v-for="child in menu.child" :key="child.id" :index='child.path'>{{child.name}}</el-menu-item>
      </div>
    </el-menu-item-group>
  </el-submenu>
  <el-menu-item v-else :index="menu.path">
    <i :class="menu.icon"></i>
    <span slot="title">{{menu.name}}</span>
  </el-menu-item>
</template>

<script>
  export default {
    name: 'MenuTree',
    props: {
      menu: {
        type: Object,
        required: true
      }
    },
    methods: {
      handleRoute (menu) {
        // 通过菜单URL跳转至指定路由
        this.$router.push(menu.url)
      }
    }
  }
</script>