import tools from '@/utils/tools';

export async function addDepartment(params,that){
	var url = "/backend/recruit/addDepartment"
	return await tools.post(url,params,that)
}

export async function getDepartmentList(params,that){
	var url = "/backend/recruit/getDepartmentList"
	return await tools.post(url,params,that)
}

export async function delDepartment(params,that){
	var url = "/backend/recruit/delDepartment"
	return await tools.post(url,params,that)
}