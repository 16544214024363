<template>
	<el-container>
		<el-aside :width="asidewidth" style="overflow-x: hidden;">
			<el-menu
				:default-active="$route.path"
				class="el-menu-vertical-demo"
				:collapse="isCollapse"
				:collapse-transition="false"
				router
				unique-opened
				background-color="#fff"
				text-color="#000"
				active-text-color="#409EFF"
				style="height: 100vh;">
					<el-menu-item style="text-align:center;color:#000">
						<router-link to='/home/index'>
							人才招聘系统
						</router-link>
					</el-menu-item>
					<menu-tree v-for="(item,index) in menuTree" :key="index" :menu="item"></menu-tree>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header style="padding: 0;">
				<el-menu mode="horizontal">
				  <el-menu-item style="float: right;" @click="loginout()">退出</el-menu-item>
					<el-menu-item style="float: right;">
						<router-link to='/home/apply'>
								<i class="el-icon-bell">
									<el-badge :value="noticeNum" style="top: -13px;left: -5px;"></el-badge>
								</i>
						</router-link>
					</el-menu-item>
					<el-menu-item style="float: right;">{{realname}}</el-menu-item>
				</el-menu>
			</el-header>
			<el-main style="background-color: #f5f5f5;">
				<router-view/>
			</el-main>
<!-- 			<el-footer>Footer</el-footer> -->
		</el-container>
	</el-container>
</template>

<script>
import {getApplyNum} from "@/api/apply"
import MenuTree from '@/components/common/MenuTree'
import { mapState } from 'vuex'
import {getMenu} from "@/api/menu"
export default {
	components: {
	  MenuTree
	},
	data() {
		return {
			setInterval:{},
			noticeNum:'',
			isCollapse:true,
			asidewidth:'160px',
			showMenuIcon:'el-icon-s-fold',
			menuTree:this.$store.state.MenuTree,
			realname:sessionStorage.getItem('name')
		}
	},
	methods: {
		getApplyNum(){
			getApplyNum({token:sessionStorage.getItem('token')},this).then(res=>{
			  this.noticeNum = res.count
			}).catch(err=>{
				this.$message.error(err)
			})
		},
		showMenu(){
			var isCollapse = this.isCollapse;
			if(isCollapse){
				this.isCollapse = false;
				this.asidewidth = '160px';
				this.showMenuIcon = 'el-icon-s-fold';
			}else{
				this.isCollapse = true;
				this.asidewidth = '64px';
				this.showMenuIcon = 'el-icon-s-unfold';
			}
		},
		getClientWidth(){
			var clientWidth = document.body.clientWidth;
			if(clientWidth>800){
				this.isCollapse=false;
				this.asidewidth = '160px';
				this.showMenuIcon = 'el-icon-s-fold';
			}else{
				this.isCollapse = true;
				this.asidewidth = '64px';
				this.showMenuIcon = 'el-icon-s-unfold';
			}
		},
		getMenuTree(){
			getMenu({
				token:sessionStorage.getItem('token')
			},this).then((res)=>{
				this.$store.commit('setmenuTree',res);
				this.menuTree = res;
			})
			.catch(function(res){
				console.log(res);
			})
		},
		loginout(){
			clearInterval(this.setInterval)
			this.$store.commit('setmenuTree','');
			sessionStorage.setItem('token','')
			this.$router.push('/')
		},
	},
	mounted () {
		const that = this;
		var menlist = this.menuTree;
		if(!menlist){
			var storemenlist = this.$store.state.MenuTree;
			if(!storemenlist){
				that.getMenuTree();
			}
			this.menuTree = this.$store.state.MenuTree;
		}
		this.getClientWidth();
		window.addEventListener('resize', () => {
			this.getClientWidth();
		})
		that.getApplyNum()
		that.setInterval = setInterval(function(){
			that.getApplyNum()
		},5000)
	},
	computed:{
		...mapState({
			MenuTree: state=>state.MenuTree
		})
	},
}
</script>

<style>
	
</style>