<template>
	<el-card class="box-card" shadow="never">
		<div slot="header" class="clearfix">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item>设置</el-breadcrumb-item>
					<el-breadcrumb-item>基本设置</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
		</div>
		<div>
			<el-form ref="form" :model="form" label-width="140px">
				<el-form-item label="微信appid">
					<el-input v-model="form.appid"></el-input>
				</el-form-item>
				<el-form-item label="微信appsecret">
					<el-input v-model="form.appsecret"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button v-show="showBtn==1" type="primary" @click="onSubmit">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-card>
</template>

<script>
import {getSetting,saveSetting} from "@/api/setting"
export default {
	data() {
		return {
			showBtn:sessionStorage.getItem('isEdit'),
			form: {
				appid: '',
				appsecret: '',
				token:sessionStorage.getItem('token')
			}
		}
	},
	methods: {
		onSubmit() {
			saveSetting(this.form,this).then(res=>{
				
			}).catch(err=>{
				this.$message.error(err);
			})
		},
		getSetting(){
			let that = this;
			getSetting({token:sessionStorage.getItem('token')},this).then(res=>{
				that.form.appid = res.wx_appid;
				that.form.appsecret = res.wx_appsecret;
			}).catch(err=>{
				this.$message.error(err);
			})
		}
	},
	mounted () {
		this.getSetting()
	}
}
</script>

<style>
</style>