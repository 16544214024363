<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
    <el-breadcrumb>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="text item">
    <el-form ref="form" :model="form" label-width="140px">
      <el-form-item label="密码" :required="true">
        <el-input type="password" v-model="form.password" placeholder="请输入.."></el-input>
      </el-form-item>
      <el-form-item label="确认密码" :required="true">
        <el-input type="password" v-model="form.repassword" placeholder="请输入.."></el-input>
      </el-form-item>
      <el-form-item label="旧密码" :required="true">
        <el-input type="password" v-model="form.oldpassword" placeholder="请输入.."></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="changePassword" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</el-card>
</template>
<script>
import {changePassword} from "@/api/user"
export default {
  data() {
    return {
      form:{
        password:'',
        repassword:'',
        oldpassword:'',
        token:sessionStorage.getItem('token'),
      }
    }
  },
  methods:{
    changePassword(){
      if(this.form.password==''){
        this.$message.error('密码不能为空')
        return false
      }
      if(this.form.password!=this.form.repassword){
        this.$message.error('密码和确认密码不一致')
        return false
      }
      if(this.form.oldpassword==''){
        this.$message.error('旧密码不能为空')
        return false
      }
      changePassword(this.form,this).then((res) => {
        this.form.password = ''
        this.form.repassword = ''
        this.form.oldpassword = ''
      })
    }
  },
  mounted () {

  }
}
</script>
<style>

</style>