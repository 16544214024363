<template>
	<el-card class="box-card" shadow="hover">
		<div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item style="line-height: 28px;">组图列表</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
			<router-link to='addPhotos' style="float:right;">
				<el-button size="mini" type="primary">新增组图</el-button>
			</router-link>
		</div>
		<div class="text item">
			<el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
			  <el-form-item label="">
			    <el-input v-model="sreachForm.words" placeholder="名称">
			      <template slot="prepend"><i class="el-icon-search"></i></template>
			    </el-input>
			  </el-form-item>
			  <el-form-item label="">
			    <el-button type="primary" @click="searchPhotos">查询</el-button>
			  </el-form-item>
			</el-form>
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%">
			  <el-table-column
			    prop="id"
					width="80"
			    label="ID">
			  </el-table-column>
			  <el-table-column
			    prop="title"
			    label="名称">
			  </el-table-column>
			  <el-table-column
			    label="添加时间"
					prop="createTime"
			    width="140">
			  </el-table-column>
			  <el-table-column
			    label="操作"
			    width="160">
			    <template slot-scope="scope">
			      <el-button v-if="showBtn==1" size="mini" type="success" plain @click="editPhotos(scope.row.id)">编辑</el-button>
			      <el-button v-else size="mini" type="success" plain @click="editPhotos(scope.row.id)">查看</el-button>
			      <el-popconfirm
			        v-show="showBtn==1"
			        confirm-button-text='好的'
			        cancel-button-text='不用了'
			        icon="el-icon-info"
			        icon-color="red"
			        @confirm="delPhotos(scope.row.id)" 
			        title="确定删除组图吗？"
							style="margin-left: 10px;"
			      >
			        <el-button size="mini" type="danger" plain slot="reference">删除</el-button>
			      </el-popconfirm>
			      
			    </template>
			  </el-table-column>
			</el-table>
			<div style="margin-top: 10px;text-align: right;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="sreachForm.page"
					:page-size="sreachForm.limit"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
	</el-card>
</template>

<script>
	import {getPhotosList,delPhotos} from "@/api/photo"
	export default {
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				tableData:[],
				currentPage:1,
				total:0,
				sreachForm:{
					page:1,
					limit:10,
					words:'',
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods: {
			getList(){
			  getPhotosList(this.sreachForm,this).then(res=>{
			    this.tableData = res.data
					this.total = res.total
					this.sreachForm.page = res.current_page
			  }).catch(err=>{
			    this.$message.error(err);
			  })
			},
			searchPhotos(){
				this.sreachForm.page = 1
				this.getList()
			},
			handleSizeChange(val) {
				this.sreachForm.limit = val
				this.sreachForm.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.sreachForm.page = val
				this.getList()
			},
			editPhotos(id){
				this.$router.push({path:"/home/editPhotos",query:{id:id}})
			},
			delPhotos(id){
				delPhotos({token:sessionStorage.getItem('token'),id:id},this).then(res=>{
					this.getList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
		},
		mounted () {
			this.getList()
		}
	}
</script>

<style>
</style>