import tools from '@/utils/tools';

export async function addJobCate(params,that){
	var url = "/backend/recruit/addJobCate"
	return await tools.post(url,params,that)
}

export async function getJobCateList(params,that){
	var url = "/backend/recruit/getJobCateList"
	return await tools.post(url,params,that)
}

export async function delJobCate(params,that){
	var url = "/backend/recruit/delJobCate"
	return await tools.post(url,params,that)
}