<template>
  <el-card class="box-card" shadow="hover">
    <div slot="header" class="clearfix" style="position:relative">
      <span style="display: inline-block;">
        <el-breadcrumb>
          <el-breadcrumb-item style="line-height: 28px;">内部推荐积分</el-breadcrumb-item>
        </el-breadcrumb>
      </span>
    </div>
    <div class="text item">
      <el-form ref="sreachForm" :model="sreachForm" inline label-width="140px">
        <el-form-item label="">
          <el-input v-model="sreachForm.words" placeholder="姓名|性别|电话|部门|岗位">
            <template slot="prepend"><i class="el-icon-search"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="searchPosts">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="recommendName"
          label="推荐人姓名">
        </el-table-column>
        <el-table-column
          prop="recommendSex"
          label="推荐人性别"
          width="120">
          <template slot-scope="scope">
		      <p v-if="scope.row.recommendSex==1">男</p>
		      <p v-if="scope.row.recommendSex==2">女</p>
		    </template>
        </el-table-column>
        <el-table-column
          prop="recommendPhone"
          label="推荐人电话"
          width="160">
        </el-table-column>
        <el-table-column
          prop="recommendDepartment"
          label="推荐人部门"
          width="180">
        </el-table-column>
        <el-table-column
          prop="recommendPost"
          label="推荐人岗位"
          width="100">
        </el-table-column>
        <el-table-column
          prop="num"
          label="积分"
          width="100">
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px;text-align: right;">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="sreachForm.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </el-card>
  </template>
  
  <script>
    import {getPointList} from "@/api/apply"
    export default {
      data() {
        return {
          title:'',
          showBtn:sessionStorage.getItem('isEdit'),
          tableData:[],
          currentPage:1,
          total:0,
          sreachForm:{
            limit:10,
            page:1,
            words:'',
            token:sessionStorage.getItem('token'),
          },
        };
      },
      methods: {
        getList(){
          getPointList(this.sreachForm,this).then(res=>{
            this.tableData = res.data
            this.total = res.total
            this.currentPage = res.current_page
          }).catch(err=>{
            this.$message.error(err);
          })
        },
        searchPosts(){
          this.sreachForm.page = 1
          this.getList()
        },
        handleSizeChange(val) {
          this.sreachForm.limit = val
          this.sreachForm.page = 1
          this.getList()
        },
        handleCurrentChange(val) {
          this.sreachForm.page = val
          this.getList()
        },
      },
      mounted () {
        this.getList()
      }
    }
  </script>
  
  <style>
  </style>