<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
		<span style="display: inline-block;">
			<el-breadcrumb>
				<el-breadcrumb-item style="line-height: 28px;">公司设置</el-breadcrumb-item>
			</el-breadcrumb>
		</span>
		<el-button size="mini" type="primary" v-show="!(isvip==2&&!data.pids)" style="float:right;" @click="showBox([])">新增</el-button>
  </div>
  <div class="text item">
    <el-input
      placeholder="输入关键字进行过滤"
      v-model="sreachForm.words"
			style="margin-bottom: 10px;">
    </el-input>
    
    <el-tree
      class="filter-tree"
      :data="data"
      default-expand-all
			:expand-on-click-node="false"
      :filter-node-method="filterNode"
      ref="tree">
			<span class="custom-tree-node" slot-scope="{ node, data }">
				<span>{{ node.label }}</span>
				<span>
					<el-button
						type="text"
						size="mini"
						@click="() => append(data)">
						添加下级
					</el-button>
					<el-button
						type="text"
						size="mini"
						v-show="!(isvip==2&&!data.pids)"
						@click="() => edit(data)">
						编辑
					</el-button>
					<el-button
						type="text"
						size="mini"
						v-show="!(isvip==2&&!data.pids)"
						@click="() => del(data)">
						删除
					</el-button>
				</span>
			</span>
    </el-tree>
  </div>
	<el-dialog :title="title" :visible.sync="showType">
		<el-form ref="form" :model="form" label-width="120px">
			<el-form-item label="上级部门">
				<el-cascader
					v-model="form.pids"
					:options="data"
					:props="{ checkStrictly: true }"
					clearable></el-cascader>
			</el-form-item>
			<el-form-item label="名称">
				<el-input v-model="form.title"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="saveCompany">保存</el-button>
				<el-button @click="showType=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</el-card>
</template>

<script>
	import {getCompanyList,saveCompany,delCompany} from "@/api/company"
  export default {
		data() {
		  return {
				title:'',
				showType:false,
				sreachForm:{
				  words:'',
				  token:sessionStorage.getItem('token'),
				},
				form:{
					title:'',
					pids:[],
					id:'',
					token:sessionStorage.getItem('token'),
				},
		    data:[],
				isvip:sessionStorage.getItem('isvip'),
		  };
		},
    methods: {
			getCompanyList(){
				getCompanyList(this.sreachForm,this).then(res=>{
				  this.data = res
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
			showBox(data){
				this.showType = true
				if(data){
					this.form.id= data.id
					this.form.pids= data.pids
					this.form.title= data.label
					this.title='编辑'
				}else{
					this.form.id=''
					this.form.pids=[]
					this.form.title=''
					this.title='新建'
				}
			},
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
			saveCompany(){
				saveCompany(this.form,this).then(res=>{
					this.showType = false
					this.getCompanyList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
			append(data){
				data.pids ? data.pids.push(data.id) : data.pids = [data.id]
				this.showType = true
				this.form.id= ''
				this.form.pids= data.pids
				this.form.title= ''
				this.title='新建'
			},
			edit(data){
				this.showBox(data)
			},
			del(data){
				delCompany({token:sessionStorage.getItem('token'),id:data.id},this).then(res=>{
					this.getCompanyList()
				}).catch(err=>{
				  this.$message.error(err)
				})
			},
    },
		mounted () {
			this.getCompanyList()
		},
		watch: {
		  'sreachForm.words'(val) {
		    this.$refs.tree.filter(val);
		  }
		}
  };
</script>

<style>
.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}
.el-tree-node__content{
	height: 30px;
}
</style>