import tools from '@/utils/tools';

//获取列表接口
export async function getList(params,that){
	var url = "/backend/user/list"
	return await tools.post(url,params,that)
}

//获取权限接口
export async function getPowerList(params,that){
	var url = "/backend/user/getPowerList"
	return await tools.post(url,params,that)
}

//添加用户接口
export async function addUser(params,that){
	var url = "/backend/user/addUser"
	return await tools.post(url,params,that)
}

//获取用户接口
export async function getUser(params,that){
	var url = "/backend/user/getUser"
	return await tools.post(url,params,that)
}

//编辑用户接口
export async function editUser(params,that){
	var url = "/backend/user/editUser"
	return await tools.post(url,params,that)
}

//删除用户接口
export async function delUser(params,that){
	var url = "/backend/user/delUser"
	return await tools.post(url,params,that)
}

//修改密码接口
export async function changePassword(params,that){
	var url = "/backend/user/changePassword"
	return await tools.post(url,params,that)
}