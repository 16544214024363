<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: 'staff' }">员工管理</el-breadcrumb-item>
      <el-breadcrumb-item>新增员工</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <div class="text item">
    <el-form ref="form" :model="form" label-width="140px">
      <p class="dividerLine">基础信息</p>
      <el-form-item label="账号" :required="true">
        <el-input v-model="form.username" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名">
        <el-input v-model="form.realname" placeholder="请输入真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="密码" :required="true">
        <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" :required="true">
        <el-input type="password" v-model="form.repassword" placeholder="请再次输入密码"></el-input>
      </el-form-item>
			<el-form-item label="部门" :required="true">
			  <el-cascader
			  	v-model="form.companyId"
			  	:options="company"
			  	clearable></el-cascader>
			</el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="状态">
          <el-option label="启用" value="1">启用</el-option>
          <el-option label="禁用" value="2">禁用</el-option>
        </el-select>
      </el-form-item>
      <p class="dividerLine">角色</p>
      <el-form ref="form" :model="form" label-width="140px">
        <el-form-item label="功能权限">
          <el-checkbox-group v-model="form.powerType">
            <el-checkbox label="1" disabled>查看</el-checkbox>
            <el-checkbox label="2">编辑</el-checkbox>
            <el-checkbox label="3">审核</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="人才库操作">
          <el-checkbox v-model="form.delTalent">删除权限</el-checkbox>
        </el-form-item>
      </el-form>
      <el-form-item label="菜单权限">
        <el-checkbox-group v-for="(v,k) in tableData" :key="k" v-model="form.powerList">
          <el-checkbox @change="powerChange(0,v.id)" :label="v.id">{{v.name}}：</el-checkbox>
          <el-checkbox @change="powerChange(1,val.id)" v-for="(val,key) in v.child" :label="val.id" :key="key">{{val.name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="" v-if="showBtn==1">
        <el-button @click="addStaff" type="primary">保存</el-button>
        <el-button @click="goback">返回</el-button>
      </el-form-item>
      <el-form-item label="" v-else>
        <el-button @click="goback">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</el-card>
</template>
<script>
import {getCompanyList,getPowerList,addStaff} from "@/api/company"
export default {
  data() {
    return {
      showBtn:sessionStorage.getItem('isEdit'),
      company:[],
			tableData:[],
      allPowerList:[],
      form:{
        username:'',
        realname:'',
        password:'',
        repassword:'',
				companyId:[],
        roleid:0,
        delTalent:false,
        status:"1",
        powerType:['1'],
        powerList:[],
        token:sessionStorage.getItem('token'),
      }
    }
  },
  methods:{
    goback(){
      this.$router.go(-1)
    },
    powerChange(n,m){
      var powerList = this.form.powerList;
      var testStr = ',' + powerList.join(",") + ",";
      var data = this.tableData;
      if(testStr.indexOf("," + m + ",") != -1){
        if(n==1){
          Object.keys(data).forEach(function(key){
            if(data[key].child){
              var data1 = data[key].child,i=0,j=0;
              Object.keys(data1).forEach(function(a){
                i++;
                if(testStr.indexOf("," + data1[a].id + ",") != -1){
                  j++;
                }
              })
              if(i==j){
                powerList.push(data[key].id);
              }
            }
          });
        }else if(n==0){
          Object.keys(data).forEach(function(key){
              if(m == data[key].id){
                if(data[key].child){
                  var data1 = data[key].child;
                  Object.keys(data1).forEach(function(a){
                    if(testStr.indexOf("," + data1[a].id + ",") === -1){
                      powerList.push(data1[a].id);
                    }
                  });
                }
              }
          });
        }
      }else{
        var undata = [];
        if(n==1){
          Object.keys(data).forEach(function(key){
            if(data[key].child){
              var data1 = data[key].child;
              Object.keys(data1).forEach(function(a){
                if(m == data1[a].id){
                  if(testStr.indexOf("," + data[key].id + ",") != -1){
                    undata.push(data[key].id);
                  }
                }
              })
            }
            Object.keys(undata).forEach(function(b){
              testStr = testStr.replace(new RegExp(','+undata[b]+',',"gm"),',')
            });
          });
        }else if(n==0){
          undata = [];
          Object.keys(data).forEach(function(key){
              if(m == data[key].id){
                if(data[key].child){
                  var data1 = data[key].child;
                  Object.keys(data1).forEach(function(a){
                    if(testStr.indexOf("," + data1[a].id + ",") != -1){
                      undata.push(data1[a].id);
                    }
                  });
                }
              }
              Object.keys(undata).forEach(function(b){
                testStr = testStr.replace(new RegExp(','+undata[b]+',',"gm"),',')
              });
          });
        }
        powerList = [];
        var newdata = testStr.split(',');
        for(var c=0;c<newdata.length;c++){
          if(newdata[c]!=''){
            powerList.push(parseInt(newdata[c]));
          }
        }
      }
      var newpowerlist = [];
      for(var i = 0; i < powerList.length; i++){
          if(newpowerlist.indexOf(powerList[i]) == -1){
              newpowerlist.push(powerList[i])
          }
      }
      this.form.powerList = newpowerlist;
    },
    getPowerList(){
      var that = this
      getPowerList({
        token:sessionStorage.getItem('token')
      },this).then((res) => {
        this.tableData = res
        Object.keys(res).forEach(function(key){
          that.form.powerList.push(res[key].id)
          if(res[key].child){
            for(var i=0;i<res[key].child.length;i++){
              that.form.powerList.push(res[key].child[i].id)
            }
          }
        });
        that.allPowerList = that.form.powerList
      })
    },
    addStaff(){
      if(this.form.username==''){
        this.$message.error('账号不能为空')
        return false
      }
			if(this.form.companyId==''){
			  this.$message.error('请选择部门')
			  return false
			}
      if(this.form.password==''){
        this.$message.error('密码不能为空')
        return false
      }
      if(this.form.password!=''&&this.form.password!=this.form.repassword){
        this.$message.error('密码和确认密码不一致')
        return false
      }
      addStaff(this.form,this).then((res) => {
        this.$router.push('/home/staff')
      })
    },
		getCompanyList(){
			getCompanyList({token:sessionStorage.getItem('token')},this).then(res=>{
				this.company = res
			}).catch(err=>{
				this.$message.error(err)
			})
		},
  },
  mounted () {
		this.getCompanyList()
    this.getPowerList()
  }
}
</script>
<style>

</style>