import tools from '@/utils/tools';

export async function addPosts(params,that){
	var url = "/backend/recruit/addPosts"
	return await tools.post(url,params,that)
}

export async function getPosts(params,that){
	var url = "/backend/recruit/getPosts"
	return await tools.post(url,params,that)
}

export async function editPosts(params,that){
	var url = "/backend/recruit/editPosts"
	return await tools.post(url,params,that)
}

export async function delPosts(params,that){
	var url = "/backend/recruit/delPosts"
	return await tools.post(url,params,that)
}

export async function getPostsList(params,that){
	var url = "/backend/recruit/getPostsList"
	return await tools.post(url,params,that)
}

export async function changPostStatus(params,that){
	var url = "/backend/recruit/changPostStatus"
	return await tools.post(url,params,that)
}
