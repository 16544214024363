<template>
	<el-card class="box-card" shadow="hover">
		<div slot="header" class="clearfix" style="position:relative">
			<span style="display: inline-block;">
				<el-breadcrumb>
					<el-breadcrumb-item :to="{ path: 'photos' }">组图列表</el-breadcrumb-item>
					<el-breadcrumb-item>编辑组图</el-breadcrumb-item>
				</el-breadcrumb>
			</span>
		</div>
		<div class="text item">
			<el-form :model="form" label-width="140px">
				<el-form-item label="名称" :required="true">
				  <el-input v-model="form.title" placeholder="请输入名称" maxlength="150" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="图片" :required="true">
				  <el-upload
						action=""
						class="avatar-uploader"
						:show-file-list="false"
						:multiple="true"
						:http-request="uploadImgList"
						:before-upload="beforeImgUpload">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="列表">
				  <el-table
						:data="form.imglist"
						border
						style="width: 100%">
						<el-table-column
							prop="img"
							label="图片"
							width="120">
							<template slot-scope="scope">
								<img width="80" height="80" :src="scope.row.img"/>
							</template>
						</el-table-column>
						<el-table-column
							prop="subtitle"
							label="副标题">
							<template slot-scope="scope">
								<el-input
									type="textarea"
									:autosize="{ minRows: 2, maxRows: 5}"
									placeholder="副标题"
									v-model="scope.row.subtitle">
								</el-input>
							</template>
						</el-table-column>
						<el-table-column
							prop="introduction"
							label="简介">
							<template slot-scope="scope">
								<el-input
									type="textarea"
									:autosize="{ minRows: 2, maxRows: 5}"
									placeholder="请输入简介"
									v-model="scope.row.introduction">
								</el-input>
							</template>
						</el-table-column>
						<el-table-column
							prop="remark"
							label="描述">
							<template slot-scope="scope">
								<el-input
									type="textarea"
									:autosize="{ minRows: 2, maxRows: 5}"
									placeholder="请输入描述内容"
									v-model="scope.row.remark">
								</el-input>
							</template>
						</el-table-column>
						<el-table-column
							prop="url"
							label="链接">
							<template slot-scope="scope">
								<el-input
									placeholder="请输入链接"
									v-model="scope.row.url">
								</el-input>
							</template>
						</el-table-column>
						<el-table-column
							label="操作"
							width="100">
							<template slot-scope="scope">
								<el-popconfirm
									v-show="showBtn==1"
									confirm-button-text='好的'
									cancel-button-text='不用了'
									icon="el-icon-info"
									icon-color="red"
									@confirm="delImg(scope.$index)" 
									title="确定删除图片吗？"
								>
									<el-button size="mini" type="danger" plain slot="reference">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item label="" v-if="showBtn==1">
				  <el-button @click="editPhotos" type="primary">保存</el-button>
					<el-button @click="goback">返回</el-button>
				</el-form-item>
				<el-form-item label="" v-else>
					<el-button @click="goback">返回</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-card>
</template>

<script>
	import {uploadImg} from "@/api/file"
	import {editPhotos,getPhotos} from "@/api/photo"
	export default {
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				form:{
					id:'',
					title:'',
					imglist:[],
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods:{
			goback(){
				this.$router.go(-1)
			},
			uploadImgList(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				form.append("token", sessionStorage.getItem('token'));
				uploadImg(form,this).then(res=>{
					this.form.imglist.push({img:res.file,subtitle:'',introduction:'',remark:'',url:''})
				})
			},
			beforeImgUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
	
				if (!isJPG&&!isPNG) {
					this.$message.error('上传图片只能是 JPG 格式和 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return (isJPG ||isPNG) && isLt2M;
			},
			delImg(key){
				this.form.imglist.splice(key,1)
			},
			editPhotos(){
				if(!this.form.title){
					this.$message.error('名称不能为空!');
					return false
				}
				editPhotos(this.form,this).then(res=>{
					this.$router.push('/home/photos')
				})
			},
			getPhotos(){
				getPhotos({token:sessionStorage.getItem('token'),id:this.form.id},this).then(res=>{
			    this.form.title = res.title
					this.form.imglist = res.imglist
				}).catch(err=>{
					this.$message.error(err)
				})
			},
		},
		mounted () {
			this.form.id = this.$route.query.id
			this.getPhotos()
		}
	}
</script>

<style>
</style>