<template>
<el-card class="box-card" shadow="hover">
  <div slot="header" class="clearfix" style="position:relative">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: 'apply' }">申请列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑申请</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
	<div class="text item">
		<el-steps v-if="form.apply.paStatus!=9" :active="form.apply.paStatus" align-center finish-status="success">
		  <el-step title="申请" :description="form.apply.applyTime"></el-step>
		  <el-step title="发送考题" :description="form.apply.questionTime"></el-step>
		  <el-step title="通知面试" :description="form.apply.noticeTime"></el-step>
		  <el-step title="通过" :description="form.apply.passTime"></el-step>
		</el-steps>
		<el-steps v-else :active="form.apply.paStatus" align-center>
		  <el-step v-if="form.apply.applyTime" title="申请" :description="form.apply.applyTime"></el-step>
		  <el-step v-if="form.apply.questionTime" title="发送考题" :description="form.apply.questionTime"></el-step>
		  <el-step v-if="form.apply.noticeTime" title="通知面试" :description="form.apply.noticeTime"></el-step>
		  <el-step v-if="form.apply.nopassTime" title="不通过" :description="form.apply.nopassTime"></el-step>
		</el-steps>
		<el-form :model="questionsForm" label-width="120px">
		<p class="dividerLine">岗位信息</p>
		<el-descriptions title="" :column="3" border>
		    <el-descriptions-item label="岗位名称">{{form.apply.title}}</el-descriptions-item>
		    <el-descriptions-item label="公司">{{form.apply.company}}</el-descriptions-item>
		    <el-descriptions-item label="岗位类别">{{form.apply.jobCate}}</el-descriptions-item>
		    <el-descriptions-item label="事业部">{{form.apply.department}}</el-descriptions-item>
		    <el-descriptions-item label="工作地点">{{form.apply.province}}-{{form.apply.city}}-{{form.apply.area}}</el-descriptions-item>
				<el-descriptions-item label="发布日期">{{form.apply.releaseDate}}</el-descriptions-item>
				<el-descriptions-item label="下线日期">{{form.apply.offlineDate}}</el-descriptions-item>
				<el-descriptions-item label="热招">{{form.apply.ishot ? '是':'否'}}</el-descriptions-item>
				<el-descriptions-item label="岗位职责">
					<p v-html="form.apply.qualifications"></p>
				</el-descriptions-item>
				<el-descriptions-item label="任职资格">
					<p v-html="form.apply.responsibilities"></p>
				</el-descriptions-item>
				<el-descriptions-item label="状态">{{form.apply.status==1 ? '正常':'禁止'}}</el-descriptions-item>
		</el-descriptions>
		<p class="dividerLine">会员信息</p>
		<el-descriptions title="" :column="3" border>
		    <el-descriptions-item label="姓名">{{form.data.realname}}</el-descriptions-item>
		    <el-descriptions-item label="头像">
					<el-image v-if="form.data.avatarUrl" style="width:60px;height: 60px;" :src="form.data.avatarUrl"></el-image>
					<img v-else style="width:60px;height: 60px;" src="@/assets/imgs/noAvatar.png"/>
				</el-descriptions-item>
		    <el-descriptions-item label="性别">
					<span v-if="form.data.gender===0">保密</span>
					<span v-else-if="form.data.gender===1">男</span>
					<span v-else-if="form.data.gender===2">女</span>
				</el-descriptions-item>
		    <el-descriptions-item label="手机号码">{{form.data.phone}}</el-descriptions-item>
		    <el-descriptions-item label="邮箱">{{form.data.email}}</el-descriptions-item>
				<el-descriptions-item label="微信号">{{form.data.wxcode}}</el-descriptions-item>
				<el-descriptions-item label="籍贯">{{form.data.native_place}}</el-descriptions-item>
				<el-descriptions-item label="证件号码">{{form.data.identification}}</el-descriptions-item>
				<el-descriptions-item label="出生日期">{{form.data.birthday}}</el-descriptions-item>
				<el-descriptions-item label="身高">{{form.data.high}}</el-descriptions-item>
				<el-descriptions-item label="政治面貌">{{form.data.political}}</el-descriptions-item>
				<el-descriptions-item label="婚姻状况">
					<span v-if="form.data.marriage===0">未知</span>
					<span v-else-if="form.data.marriage===1">已婚</span>
					<span v-else-if="form.data.marriage===2">未婚</span>
				</el-descriptions-item>
				<el-descriptions-item label="兴趣爱好">{{form.data.hobby}}</el-descriptions-item>
				<el-descriptions-item label="户口所在地">{{form.data.replace}}</el-descriptions-item>
				<el-descriptions-item label="现居住地">{{form.data.place}}</el-descriptions-item>
				<el-descriptions-item label="学历">{{form.data.education}}</el-descriptions-item>
				<el-descriptions-item label="工作经验">{{form.data.experience}}</el-descriptions-item>
				<el-descriptions-item label="归档状况">
					<span v-if="form.data.archive===1">已归档</span>
					<span v-else>未归档</span>
				</el-descriptions-item>
				<el-descriptions-item label="备注">{{form.data.remark}}</el-descriptions-item>
			</el-descriptions>
			<p class="dividerLine">求职意向</p>
			<el-descriptions title="" :column="3" border>
			    <el-descriptions-item label="应聘岗位">{{form.intention.position}}</el-descriptions-item>
			    <el-descriptions-item label="期望月薪">{{form.intention.wages}}</el-descriptions-item>
			    <el-descriptions-item label="期望工作地区">{{form.intention.place}}</el-descriptions-item>
			    <el-descriptions-item label="期待从事职业">{{form.intention.occupation}}</el-descriptions-item>
			    <el-descriptions-item label="期望从事行业">{{form.intention.industry}}</el-descriptions-item>
					<el-descriptions-item label="目前状况">{{form.intention.situation}}</el-descriptions-item>
					<el-descriptions-item label="到岗时间">{{form.intention.arrivalTime}}</el-descriptions-item>
					<el-descriptions-item label="工作性质">{{form.intention.nature}}</el-descriptions-item>
			</el-descriptions>
			<p class="dividerLine">自我评述</p>
			{{form.comment}}
			<p class="dividerLine">教育经历</p>
				<el-table
				  :data="form.educationalData"
				  border
				  style="width: 100%">
				  <el-table-column
				    prop="educationalTime"
				    label="就学时间"
						width="180">
				  </el-table-column>
				  <el-table-column
						prop="school"
				    label="学校名称"
				    width="200">
				  </el-table-column>
					<el-table-column
						prop="degree"
					  label="学位/学历"
					  width="140">
					</el-table-column>
					<el-table-column
						prop="major"
					  label="就学专业"
					  width="160">
					</el-table-column>
					<el-table-column
						prop="professional"
					  label="专业描述">
					</el-table-column>
				</el-table>
			<p class="dividerLine">培训经历</p>
				<el-table
				  :data="form.trainingData"
				  border
				  style="width: 100%">
				  <el-table-column
				    prop="trainingTime"
				    label="培训时间"
						width="180">
				  </el-table-column>
				  <el-table-column
						prop="motto"
				    label="校训机构/学校"
				    width="200">
				  </el-table-column>
					<el-table-column
						prop="remark"
					  label="培训描述">
					</el-table-column>
					<el-table-column
						prop="represent"
					  label="专业描述">
					</el-table-column>
				</el-table>
			<p class="dividerLine">工作经历</p>
				<el-table
				  :data="form.workingData"
				  border
				  style="width: 100%">
				  <el-table-column
				    prop="workingTime"
				    label="工作时间"
						width="180">
				  </el-table-column>
				  <el-table-column
						prop="company"
				    label="公司名称"
				    width="140">
				  </el-table-column>
					<el-table-column
						prop="position"
					  label="担任职位"
					  width="140">
					</el-table-column>
					<el-table-column
						prop="size"
					  label="公司规模"
					  width="140">
					</el-table-column>
					<el-table-column
						prop="industry"
					  label="行业类别"
					  width="120">
					</el-table-column>
					<el-table-column
						prop="nature"
					  label="企业性质"
					  width="120">
					</el-table-column>
					<el-table-column
						prop="content"
					  label="工作内容">
					</el-table-column>
				</el-table>
			<p class="dividerLine">项目经验</p>
				<el-table
					:data="form.workingData"
					border
					style="width: 100%">
					<el-table-column
						prop="workingTime"
						label="工作时间"
						width="180">
					</el-table-column>
					<el-table-column
						prop="company"
						label="公司名称"
						width="140">
					</el-table-column>
					<el-table-column
						prop="position"
						label="担任职位"
						width="140">
					</el-table-column>
					<el-table-column
						prop="size"
						label="公司规模"
						width="140">
					</el-table-column>
					<el-table-column
						prop="industry"
						label="行业类别"
						width="120">
					</el-table-column>
					<el-table-column
						prop="nature"
						label="企业性质"
						width="120">
					</el-table-column>
					<el-table-column
						prop="content"
						label="工作内容">
					</el-table-column>
				</el-table>
			<p class="dividerLine">其他信息</p>
				{{form.data.other}}
			<p class="dividerLine">附件信息</p>
				<a v-if="form.data.file" class="el-button" target="_blank" :href="form.data.file">查看简历</a>
				<p v-else style="text-align: center;">暂无附件</p>
			<p class="dividerLine">附件信息</p>
				<a v-if="form.data.plant" class="el-button" target="_blank" :href="form.data.plant">查看作品</a>
				<p v-else style="text-align: center;">暂无附件</p>
			<p class="dividerLine" v-show="form.apply.cateid">推荐人</p>
			<el-descriptions v-if="form.apply.recommendType==1" title="" :column="3" border  v-show="form.apply.cateid">
			    <el-descriptions-item label="推荐人姓名">{{form.apply.recommendName}}</el-descriptions-item>
			    <el-descriptions-item label="推荐人电话">{{form.apply.recommendPhone}}</el-descriptions-item>
			    <el-descriptions-item label="推荐人工号">{{form.apply.recommendDepartment}}</el-descriptions-item>
			</el-descriptions>
			<el-descriptions v-else title="" :column="3" border  v-show="form.apply.cateid">
			    <el-descriptions-item label="推荐人姓名">{{form.apply.recommendName}}</el-descriptions-item>
			    <el-descriptions-item label="推荐人电话">{{form.apply.recommendPhone}}</el-descriptions-item>
			</el-descriptions>
			<p class="dividerLine" v-show="form.apply.paStatus==2||form.apply.paStatus==3||form.apply.paStatus==4">考题信息</p>
			<el-descriptions title="" :column="3" border v-show="form.apply.paStatus==2||form.apply.paStatus==3||form.apply.paStatus==4">
				<el-descriptions-item label="二维码">
					<el-image style="width:60px;height: 60px;" :src="form.apply.questionImg"></el-image>
				</el-descriptions-item>
				<el-descriptions-item label="备注">{{form.apply.questionRemark}}</el-descriptions-item>
			</el-descriptions>
			<el-button v-show="form.apply.paStatus==2||form.apply.paStatus==3||form.apply.paStatus==4" style="margin: 20px;" size="mini" type="primary" @click="delcode">删除考题二维码</el-button>
			<p class="dividerLine" v-show="form.apply.paStatus==3||form.apply.paStatus==4">通知面试信息</p>
			<el-descriptions title="" :column="3" border v-show="form.apply.paStatus==3||form.apply.paStatus==4">
				<el-descriptions-item label="备注">{{form.apply.noticeRemark}}</el-descriptions-item>
			</el-descriptions>
			<p class="dividerLine" v-show="form.apply.paStatus==3||form.apply.paStatus==4">通过面试信息</p>
			<el-descriptions title="" :column="3" border v-show="form.apply.paStatus==3||form.apply.paStatus==4">
				<el-descriptions-item label="备注">{{form.apply.passRemark}}</el-descriptions-item>
			</el-descriptions>
			<p class="dividerLine" v-show="form.apply.paStatus==3||form.apply.paStatus==4">录用函信息</p>
			<el-row v-show="form.apply.paStatus==3||form.apply.paStatus==4">
				<a v-if="form.apply.letter" class="el-button" target="_blank" :href="form.apply.letter">查看录用函</a>
				<el-button v-if="form.apply.letter" @click="delletter()" type="primary">删除录用函</el-button>
				<el-button v-if="!form.apply.letter" @click="addletter()" type="primary">创建录用函</el-button>
			</el-row>
			<el-form-item style="margin-top: 20px;" label="" v-if="showBtn==1">
			  <el-button v-show="form.apply.paStatus==1" @click="showQuestionsbox()" type="primary">发送考题</el-button>
				<el-button v-show="form.apply.paStatus==1||form.apply.paStatus==2" @click="showNoticebox()" type="primary">通知面试</el-button>
				<el-button v-show="form.apply.paStatus==3" @click="showPassbox()" type="primary">通过面试</el-button>
				<el-button v-show="form.apply.paStatus==1||form.apply.paStatus==2||form.apply.paStatus==3" @click="showNoPassbox()" type="danger">不通过</el-button>
				<el-button v-show="!form.data.archive" @click="showArchive()">归档</el-button>
				<el-button @click="goback">返回</el-button>
			</el-form-item>
	  </el-form>
	</div>
	<el-dialog title="发送考题" :visible.sync="questionsbox">
		<el-form :model="questionsForm" label-width="120px">
			<el-form-item label="上传考题二维码">
				<el-upload
				  class="avatar-uploader"
				  action=""
				  :show-file-list="false"
				  :http-request="uploadImgList"
				  :before-upload="beforeImgUpload">
				  <img v-if="questionsForm.img" :src="questionsForm.img" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="questionsForm.remark"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="sendQuestions">确定</el-button>
				<el-button @click="questionsbox=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	<el-dialog title="通知面试" :visible.sync="noticebox">
		<el-form :model="noticeForm" label-width="120px">
			<el-form-item label="备注">
				<el-date-picker
					v-model="noticeForm.usernoticeTime"
					type="datetime"
					placeholder="选择日期时间">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="地点">
				<el-input v-model="noticeForm.address"></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="noticeForm.remark"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="sendNotice">确定</el-button>
				<el-button @click="noticebox=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	<el-dialog title="归档" :visible.sync="archivebox">
		<el-form :model="archiveForm" label-width="120px">
			<el-form-item label="备注">
				<el-input v-model="archiveForm.remark"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="setarchive">确定</el-button>
				<el-button @click="archivebox=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	<el-dialog title="通过面试" :visible.sync="passbox">
		<el-form :model="passForm" label-width="120px">
			<el-form-item label="备注">
				<el-input v-model="passForm.remark"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="sendPass">确定</el-button>
				<el-button @click="passbox=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	<el-dialog title="拒绝" :visible.sync="nopassbox">
		<el-form :model="nopassForm" label-width="120px">
			<el-form-item label="备注">
				<el-input v-model="nopassForm.remark"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="nosendPass">确定</el-button>
				<el-button @click="nopassbox=false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	<el-dialog title="创建录用函" width="80%" :visible.sync="letterbox">
		<el-form :model="form">
			<el-form-item label="录用函内容" label-width="140px">
				<vue-ueditor-wrap v-model="detail" :config="ueConfig"></vue-ueditor-wrap>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="letterbox = false">取 消</el-button>
			<el-button type="primary" @click="createLetter">保 存</el-button>
		</div>
	</el-dialog>
</el-card>
</template>

<script>
	import VueUeditorWrap from 'vue-ueditor-wrap'
	import {uploadImg} from "@/api/file"
	import {editApply,getApply,sendQuestions,sendNotice,sendPass,nosendPass,delcode,createLetter,delletter,setarchive} from "@/api/apply"
	import { Quill, quillEditor } from 'vue-quill-editor'
	import cityData from "@/assets/js/citydata.js"
	import detail from "@/assets/js/letter.js"
	export default {
		components: {
			quillEditor,
			VueUeditorWrap
		},
		data() {
			return {
				showBtn:sessionStorage.getItem('isEdit'),
				questionsbox:false,
				noticebox:false,
				passbox:false,
				nopassbox:false,
				letterbox:false,
				archivebox:false,
				detail:'',
				ueConfig:{
					// 初始容器高度
					initialFrameHeight: 300,
					// 初始容器宽度
					initialFrameWidth: '99%',
					// 上传文件接口
					enableAutoSave: false,
					elementPathEnable: false,
					wordCount: false,
				},
				companyList:[],
				editorOption: { //  富文本编辑器配置
					placeholder: '请输入',
				},
				title:'',
				path:{},
				cityData:[],
				questionsForm:{
					id:'',
					img:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
				noticeForm:{
					id:'',
					usernoticeTime:'',
					address:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
				archiveForm:{
					id:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
				passForm:{
					id:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
				nopassForm:{
					id:'',
					remark:'',
					token:sessionStorage.getItem('token'),
				},
				form:{
					id:'',
					data:[],
					apply:[],
					educationalData:[],
					intention:[],
					projectData:[],
					trainingData:[],
					workingData:[],
					token:sessionStorage.getItem('token'),
				},
			};
		},
		methods:{
			goback(){
				this.$router.go(-1)
			},
			delletter(){
				let that = this
				delletter({id:that.form.id,token:sessionStorage.getItem('token')},this).then(res=>{
					that.getApply()
				})
			},
			addletter(){
				this.letterbox = true
				this.detail = detail
			},
			createLetter(){
				let that = this
				createLetter({id:that.form.id,detail:this.detail,token:sessionStorage.getItem('token')},this).then(res=>{
					that.letterbox = false
					that.getApply()
				})
			},
			uploadImgList(params){
				const file = params.file,form = new FormData();
				form.append("file", file)
				form.append("token", sessionStorage.getItem('token'));
				uploadImg(form,this).then(res=>{
					this.questionsForm.img=res.file
				})
			},
			beforeImgUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				
				if (!isJPG&&!isPNG) {
					this.$message.error('上传图片只能是 JPG 格式和 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return (isJPG ||isPNG) && isLt2M;
			},
			sendNotice(){
				sendNotice(this.noticeForm,this).then(res=>{
			    this.noticebox = false
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			setarchive(){
				setarchive(this.archiveForm,this).then(res=>{
			    this.archivebox = false
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			nosendPass(){
				nosendPass(this.nopassForm,this).then(res=>{
			    this.nopassbox = false
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			delcode(){
				delcode({id:this.form.id,token:sessionStorage.getItem('token'),},this).then(res=>{
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			sendPass(){
				sendPass(this.passForm,this).then(res=>{
			    this.passbox = false
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			sendQuestions(){
				sendQuestions(this.questionsForm,this).then(res=>{
			    this.questionsbox = false
					this.getApply()
				}).catch(err=>{
					this.$message.error(err)
				})
			},
			showQuestionsbox(){
				this.questionsbox = true
				this.questionsForm.id = this.$route.query.id
				this.questionsForm.img = ''
				this.questionsForm.remark = ''
			},
			showNoticebox(){
				this.noticebox = true
				this.noticeForm.id = this.$route.query.id
				this.noticeForm.remark = ''
				this.noticeForm.address = ''
				this.noticeForm.usernoticeTime = ''
			},
			showPassbox(){
				this.passbox = true
				this.passForm.id = this.$route.query.id
				this.passForm.remark = ''
			},
			showArchive(){
				this.archivebox = true
				this.archiveForm.id = this.$route.query.id
				this.archiveForm.remark = ''
			},
			showNoPassbox(){
				this.nopassbox = true
				this.nopassForm.id = this.$route.query.id
				this.nopassForm.remark = ''
			},
			getApply(){
				getApply({token:sessionStorage.getItem('token'),id:this.form.id},this).then(res=>{
			    this.form.data = res.data
					this.form.apply = res.apply
					this.form.educationalData = res.educational
					this.form.intention = res.intention
					this.form.projectData = res.project
					this.form.trainingData = res.training
					this.form.workingData = res.working
				}).catch(err=>{
					this.$message.error(err)
				})
			},
		},
		mounted () {
			this.form.id = this.$route.query.id
			this.cityData = cityData
			this.getApply()
		}
	}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>